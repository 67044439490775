import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';

const deduceNumberCharacteristics = ({validation, step}) => {
  const items = (validation || '').split('|');
  let isInteger = true;
  let min = '';
  let max = '';
  items.forEach(item => {
    if (item === 'numeric') {
      isInteger = false;
    } else if (item.includes('min')) {
      [, min] = item.split(':');
    } else if (item.includes('max')) {
      [, max] = item.split(':');
    } else if (item.includes('between')) {
      const [, between] = item.split(':');
      [min, max] = between.split(',');
    }
  });

  return {
    valueType: isInteger ? 'integer' : 'numeric',
    min,
    max,
    step: step || (isInteger ? 1 : ''),
  };
};

const NumberControl = ({
  field,
  id,
  value,
  disabled,
  onChange,
  onBlur,
  onFocus,
}) => {
  const {valueType, min, max, step} = useMemo(
    () => deduceNumberCharacteristics(field),
    [field],
  );

  const handleChange = useCallback(
    (ev, data) => {
      const numericValue =
        valueType === 'numeric'
          ? parseFloat(ev.target.value)
          : parseInt(ev.target.value, 10);

      if (!Number.isNaN(numericValue)) {
        onChange(numericValue, {data});
      } else {
        onChange('', {data});
      }
    },
    [onChange, valueType],
  );

  return (
    <Input
      type="number"
      id={id}
      value={value || (value === 0 ? value : '')}
      step={step}
      min={min}
      max={max}
      disabled={disabled}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

NumberControl.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

NumberControl.defaultProps = {
  value: undefined,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
};

export default NumberControl;
