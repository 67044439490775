import React from 'react';
import {Router} from '@reach/router';
import loadable from '@loadable/component';
import AdminApp from '../components/admin/AdminApp';
import {isBrowser} from '../config';

// Async load components to enable code splitting
// and prevent dependency resolution during Gatsby build
const AsyncDashboard = loadable(() => import('../pages-admin'));
const AsyncArticleList = loadable(() => import('../pages-admin/articles'));
const AsyncArticleNew = loadable(() => import('../pages-admin/articles/new'));
const AsyncArticleEdit = loadable(() => import('../pages-admin/articles/edit'));
const AsyncArticleCategoryList = loadable(() =>
  import('../pages-admin/article-categories'),
);
const AsyncArticleCategoryNew = loadable(() =>
  import('../pages-admin/article-categories/new'),
);
const AsyncArticleCategoryEdit = loadable(() =>
  import('../pages-admin/article-categories/edit'),
);
const AsyncNotFound = loadable(() => import('../pages-admin/404'));
const AsyncPageList = loadable(() => import('../pages-admin/pages'));
const AsyncHomeEdit = loadable(() => import('../pages-admin/pages/home'));
const AsyncAboutEdit = loadable(() => import('../pages-admin/pages/about'));
const AsyncWebMessages = loadable(() => import('../pages-admin/web-messages'));
const AsyncSettingsEdit = loadable(() => import('../pages-admin/settings'));

const Admin = ({location}) =>
  isBrowser ? (
    <AdminApp location={location}>
      <Router basepath="/admin">
        <AsyncDashboard path="/" />
        <AsyncArticleList path="/articles" />
        <AsyncArticleNew path="/articles/new" />
        <AsyncArticleEdit path="/articles/:articleId" />
        <AsyncArticleCategoryList path="/article-categories" />
        <AsyncArticleCategoryNew path="/article-categories/new" />
        <AsyncArticleCategoryEdit path="/article-categories/:categoryId" />
        <AsyncPageList path="/pages" />
        <AsyncHomeEdit path="/pages/home" />
        <AsyncAboutEdit path="/pages/about" />
        <AsyncWebMessages path="/web-messages" />
        <AsyncSettingsEdit path="/settings" />
        <AsyncNotFound default />
      </Router>
    </AdminApp>
  ) : null;

export default Admin;
