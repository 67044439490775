import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {css} from 'styled-components';
import reactDateTimeStyles from 'react-datetime/css/react-datetime.css';
import DateTime from 'react-datetime';
import moment from 'moment';

const getFormats = field => {
  const {format, dateFormat} = field;
  let {timeFormat} = field;

  // dateFormat and timeFormat are strictly for modifying
  // input field with the date/time pickers
  // show time-picker? false hides it, true shows it using default format
  if (typeof timeFormat === 'undefined') {
    timeFormat = true;
  }

  return {format, dateFormat, timeFormat};
};

const isValidDate = dt =>
  moment.isMoment(dt) || dt instanceof Date || dt === '' || dt == null;

const DateTimeControl = ({
  field,
  id,
  value,
  onChange,
  onBlur,
  onFocus,
  disabled,
}) => {
  const {format, dateFormat, timeFormat} = getFormats(field);

  const handleChange = useCallback(
    dt => {
      if (!isValidDate(dt)) {
        return;
      }

      if (format) {
        const formatted = dt ? moment(dt).format(format) : '';
        onChange(formatted);
      } else {
        const formatted = moment.isMoment(dt) ? dt.toDate() : dt;
        onChange(formatted);
      }
    },
    [onChange, format],
  );

  const handleOnBlur = useCallback(
    dt => {
      if (!isValidDate(dt)) {
        const parsedDate = moment(dt);
        if (parsedDate.isValid()) {
          handleChange(dt);
        } else {
          // eslint-disable-next-line no-alert
          window.alert('The date you entered is invalid');
        }
      }

      onBlur();
    },
    [onBlur, handleChange],
  );

  return (
    <div
      css={css`
        ${reactDateTimeStyles}
      `}>
      <DateTime
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        value={moment(value, format)}
        onChange={handleChange}
        onOpen={onFocus}
        onClose={handleOnBlur}
        inputProps={{id, disabled}}
      />
    </div>
  );
};

DateTimeControl.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DateTimeControl.defaultProps = {
  id: undefined,
  value: undefined,
  disabled: false,
};

export default DateTimeControl;
