import Uppy from '@uppy/core';
import {isDevelop} from '../config';
import AhpGqlUpload from './ahp-gql-upload';
import AttachmentGql from '../gql/frontend/AttachmentGql';

// eslint-disable-next-line import/prefer-default-export
export const attachmentUppy = onSuccess => {
  const uppy = Uppy({
    debug: isDevelop,
    autoProceed: false,
    restrictions: {
      maxFileSize: 10000000, // 10MB
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.jpg', '.jpeg', '.png'],
    },
    onBeforeUpload: files => {
      // Ensure all files have titles assigned
      for (const file of Object.values(files)) {
        const {title} = file.meta;
        if (!(title && title.length)) {
          uppy.log(
            `Aborting upload because ${JSON.stringify(
              file,
            )} does not have a title`,
          );
          uppy.info(
            `Enter image info for ${file.name} before uploading. Click the pencil icon to edit`,
            'error',
            4000,
          );
          return false;
        }
      }

      return files;
    },
  })
    .use(AhpGqlUpload, {
      mutation: AttachmentGql.queries.upload,
      fieldName: 'upload',
      metaFields: ['title', 'caption', 'altText', 'description'],
    })
    .on('upload-success', (file, data) => {
      const {uploadAttachment: attachment} = data;
      if (onSuccess) {
        onSuccess(attachment);
      }
    });

  return uppy;
};
