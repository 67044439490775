import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from 'semantic-ui-react';

const BooleanControl = ({id, value, disabled, onChange, onBlur, onFocus}) => (
  <Checkbox
    toggle
    id={id}
    checked={value}
    disabled={disabled}
    onChange={(ev, data) => {
      onChange(data.checked, data);
    }}
    onBlur={onBlur}
    onFocus={onFocus}
  />
);

BooleanControl.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

BooleanControl.defaultProps = {
  value: false,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
};

export default BooleanControl;
