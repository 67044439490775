import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import inflection from 'inflection';
import {getDataFetcher, getSelectOptionConverter} from '../../../registry';
import logger from '../../../../../../logger';

const convertToOption = ({item, textField = 'title', valueField = 'id'}) => {
  if (typeof item === 'string') {
    return {key: item, text: inflection.humanize(item), value: item};
  }

  if (item[valueField]) {
    return {
      key: item[valueField],
      value: item[valueField],
      text: item[textField],
      content: item[textField],
    };
  }

  return item;
};

const RelationControl = ({
  field,
  id,
  value,
  disabled,
  error,
  onChange,
  onBlur,
  onFocus,
}) => {
  const {placeholder, multiple = false, validation, relation} = field;
  const required = validation && validation.includes('required');
  const clearable = !required;
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const {
      name: relationName,
      dataFetcher,
      optionConverter,
      valueField,
      textField,
      query: relationQuery,
    } = relation;
    const selectOptionConverter =
      getSelectOptionConverter(optionConverter || relationName) ||
      convertToOption;
    const fetch = getDataFetcher(dataFetcher || relationName);

    // Dispatch load
    const load = async () => {
      setLoading(true);
      try {
        await fetch(relationQuery)
          .then(items =>
            items.map(item =>
              selectOptionConverter({item, textField, valueField}),
            ),
          )
          .then(setOptions);
      } catch (e) {
        setLoadError(e);
        logger.error('Error loading relation options', e);
      } finally {
        setLoading(false);
      }
    };

    load();
  }, [relation]);

  return (
    <Dropdown
      id={id}
      value={value}
      fluid
      search
      selection
      loading={loading}
      clearable={clearable}
      multiple={multiple}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      error={!!loadError || error}
      onChange={(ev, data) => {
        onChange(data.value, data);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

RelationControl.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

RelationControl.defaultProps = {
  value: undefined,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
  error: false,
};

export default RelationControl;
