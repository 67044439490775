import React from 'react';
import CmsWidgetFile from '../file';
import previewComponent from './MediaPreview';

const FileControl = CmsWidgetFile.controlComponent;
const controlComponent = props => <FileControl {...props} forMedia />;

const Widget = (opts = {}) => ({
  name: 'media',
  controlComponent,
  previewComponent,
  ...opts,
});

export const CmsWidgetMedia = {
  Widget,
  controlComponent,
  previewComponent,
};
export default CmsWidgetMedia;
