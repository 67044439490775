import React, {useState} from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import {Button, Grid, Header, Image} from 'semantic-ui-react';
import SocialMenu from '../../site/elements/SocialMenu';
import logo from '../../../images/logo-light.png';
import {colorsRaw} from '../../../theme';
import SubscribeModal from '../../site/SubscribeModal';

const HERO_HEIGHT = '90vh';
const MastHead = styled.div`
  width: 100%;
  height: ${HERO_HEIGHT};
  min-height: 500px;
  color: rgba(255, 255, 255, 0.9);
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.667) !important;

  .social-menu {
    .item {
      color: ${colorsRaw.white};

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .logo {
    max-height: 128px;
    margin-bottom: 1.25rem;
  }

  .tagline {
    margin-top: 1rem;
  }
`;

const MastHeadGrid = styled(Grid)`
  height: 100%;
`;

const HeroImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero1: file(
          relativePath: {
            eq: "pages/home/masthead/greetings-from-austin-tx-mural.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 675) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `,
  );

  return (
    <Img
      style={{
        position: 'absolute',
        zIndex: -5,
        top: 0,
        width: '100%',
        height: HERO_HEIGHT,
        minHeight: 500,
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-around',
        // background: `linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        // 					linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(17, 33, 6, 0.75))`,
        backgroundSize: 'cover',
      }}
      fluid={data.hero1.childImageSharp.fluid}
      alt="Home and Property info in Austin and Surrounding Areas"
    />
  );
};

const HomeHero = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <header className="home-header">
      <HeroImage />

      <MastHead>
        <MastHeadGrid padded>
          <Grid.Column width={16}>
            <SocialMenu centered inverted />
          </Grid.Column>
          <Grid.Column width={16}>
            <Image
              className="logo"
              centered
              src={logo}
              alt="Austin Home Properties"
            />
            <hr
              style={{
                marginTop: '0.33rem',
                marginBottom: '0.33rem',
                width: '50%',
                color: 'white',
              }}
            />
            <p className="tagline text-upper text-centered">
              Real Estate and Lifestyle in Austin Texas
            </p>
          </Grid.Column>
          <Grid.Column className="text-centered" width={16}>
            <Header as="h2" inverted>
              Austin area real estate news and updates
              <Header.Subheader>
                Directly into your inbox every Month
              </Header.Subheader>
            </Header>
            <Button
              primary
              className="large-tm"
              size="huge"
              onClick={() => setModalOpen(true)}>
              Subscribe
            </Button>
          </Grid.Column>
        </MastHeadGrid>
      </MastHead>

      {modalOpen && (
        <SubscribeModal
          campaign="Home Page Hero"
          open
          onClose={() => setModalOpen(false)}
        />
      )}
    </header>
  );
};

export default HomeHero;
