/* eslint-disable class-methods-use-this */
import {Quill} from 'react-quill';

const EmbedBlot = Quill.import('blots/embed');
const BreakBlot = Quill.import('blots/break');

// See:
// * https://codepen.io/mackermedia/pen/gmNwZP
// * https://github.com/quilljs/quill/issues/252#issuecomment-292565427
class SmartBreak extends BreakBlot {
  length() {
    return 1;
  }

  value() {
    return '\n';
  }

  insertInto(parent, ref) {
    EmbedBlot.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';

export default SmartBreak;
