import React from 'react';
import {
  registerDataFetcher,
  registerPreviewStyle,
  registerPreviewTemplate,
  registerValueSerializer,
  registerWidget,
} from './registry';
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import styles from '!css-loader!less-loader!fomantic-ui-less/semantic.less';
import CmsWidgetString from './editor/widgets/string';
import CmsWidgetNumber from './editor/widgets/number';
import CmsWidgetText from './editor/widgets/text';
import CmsWidgetDateTime from './editor/widgets/datetime';
import CmsWidgetRichText from './editor/widgets/richText';
import CmsWidgetMedia from './editor/widgets/media';
import CmsWidgetFile from './editor/widgets/file';
import CmsWidgetRelation from './editor/widgets/relation';
import CmsWidgetBoolean from './editor/widgets/boolean';
import CmsWidgetSelect from './editor/widgets/select';
import articleCategoriesDataFetcher from './editor/data-fetchers/articleCategoriesDataFetcher';
import articleTagsDataFetcher from './editor/data-fetchers/articleTagsDataFetcher';
import dateTimeSerializer from './editor/value-serializers/dateTimeSerializer';
import SingleArticleTemplate from '../../shared/SingleArticle/SingleArticleTemplate';
import HomePage from '../../shared/HomePage/HomePage';

const bootstrap = () => {
  registerPreviewStyle(styles.toString(), {raw: true});

  registerWidget([
    CmsWidgetString.Widget(),
    CmsWidgetNumber.Widget(),
    CmsWidgetText.Widget(),
    CmsWidgetMedia.Widget(),
    CmsWidgetFile.Widget(),
    CmsWidgetSelect.Widget(),
    CmsWidgetRichText.Widget(),
    // CmsWidgetList.Widget(),
    // CmsWidgetObject.Widget(),
    CmsWidgetRelation.Widget(),
    CmsWidgetBoolean.Widget(),
    // CmsWidgetMap.Widget(),
    CmsWidgetDateTime.Widget(),
    // CmsWidgetCode.Widget(),
  ]);

  registerDataFetcher('articleCategories', articleCategoriesDataFetcher);
  registerDataFetcher('articleTags', articleTagsDataFetcher);

  registerValueSerializer('datetime', dateTimeSerializer);

  registerPreviewTemplate('articles', ({entry}) => (
    <SingleArticleTemplate {...entry} />
  ));
  registerPreviewTemplate('homePage', HomePage);
};

export default {
  bootstrap,
};
