import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import inflection from 'inflection';
import {getDataFetcher, getSelectOptionConverter} from '../../../registry';
import logger from '../../../../../../logger';

const optionToString = option =>
  option && option.value ? option.value : option;

const convertToOption = ({item, textField = 'label', valueField = 'value'}) => {
  if (typeof item === 'string') {
    return {key: item, text: inflection.humanize(item), value: item};
  }

  if (item[valueField]) {
    return {
      key: item[valueField],
      value: item[valueField],
      text: item[textField],
    };
  }

  return item;
};

const optionsContainValue = (haystack, needle, valueField = 'value') =>
  haystack.find(item =>
    item[valueField] ? item[valueField] === needle : item === needle,
  );

const SelectControl = ({
  field,
  id,
  value,
  disabled,
  error,
  onChange,
  onBlur,
  onFocus,
}) => {
  const {
    name,
    placeholder,
    multiple = false,
    validation,
    options: fieldOptions,
    fetchOptions,
    allowAdditions,
  } = field;
  const {
    dataFetcher,
    optionConverter,
    valueField,
    textField,
    query: fetchQuery,
  } = fetchOptions || {};
  const selectOptionConverter =
    getSelectOptionConverter(optionConverter || name) || convertToOption;
  const fetch = getDataFetcher(dataFetcher || name);

  const required = validation && validation.includes('required');
  const clearable = !required;
  const [loading, setLoading] = useState(!!fetchOptions);
  const [loadError, setLoadError] = useState(null);
  const [loadedOptions, setLoadedOptions] = useState([]);

  const handleChange = useCallback(
    (val, data) => {
      const isEmpty = multiple ? !(val && val.length) : !val;
      if (required && isEmpty && multiple) {
        onChange([], data);
      } else if (isEmpty) {
        onChange(null, data);
      } else if (multiple) {
        const v = (Array.isArray(val) ? val : [val]).map(optionToString);
        onChange(v, data);
      } else {
        onChange(optionToString(val), data);
      }
    },
    [onChange, multiple, required],
  );
  const allOptions = useMemo(() => {
    const fieldOptionsConverted = (fieldOptions || []).map(item =>
      selectOptionConverter({item, textField, valueField}),
    );
    let all = fieldOptionsConverted.concat(loadedOptions);
    if (allowAdditions) {
      // Ensure the additional values are presented in the options
      if (typeof value === 'string' && value.length) {
        const valueConverted = selectOptionConverter({item: value});
        if (optionsContainValue(all, valueConverted, valueField)) {
          all.unshift(valueConverted);
        }
      } else if (Array.isArray(value)) {
        const addedOptions = value.filter(
          item => !optionsContainValue(all, item, valueField),
        );
        if (addedOptions.length) {
          all = addedOptions
            .map(item => selectOptionConverter({item, textField, valueField}))
            .concat(all);
        }
      }
    }

    return all;
  }, [
    allowAdditions,
    fieldOptions,
    loadedOptions,
    selectOptionConverter,
    textField,
    value,
    valueField,
  ]);

  useEffect(() => {
    if (fetch) {
      // Dispatch load
      const load = async () => {
        setLoading(true);
        try {
          await fetch(fetchQuery)
            .then(items =>
              items.map(item =>
                selectOptionConverter({item, textField, valueField}),
              ),
            )
            .then(setLoadedOptions);
        } catch (e) {
          setLoadError(e);
          logger.error('Error loading select options', e);
        } finally {
          setLoading(false);
        }
      };

      load();
    }
  }, [
    fetch,
    fetchOptions,
    fetchQuery,
    name,
    selectOptionConverter,
    textField,
    valueField,
  ]);

  if (!allOptions.length && !allowAdditions) {
    return <div>Error rendering select control for {name}: No options</div>;
  }

  let normalizedVal;
  if (multiple) {
    normalizedVal = (Array.isArray(value) ? value : [value]).map(
      v => selectOptionConverter({item: v, textField, valueField}).value,
    );
  } else {
    normalizedVal = value
      ? selectOptionConverter({item: value, textField, valueField}).value
      : '';
  }

  return (
    <Dropdown
      id={id}
      value={normalizedVal}
      fluid
      search
      selection
      allowAdditions={allowAdditions}
      loading={loading}
      clearable={clearable}
      multiple={multiple}
      options={allOptions}
      placeholder={placeholder}
      disabled={disabled}
      error={!!loadError || error}
      onChange={(ev, data) => {
        handleChange(data.value, data);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

SelectControl.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ]),
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

SelectControl.defaultProps = {
  value: undefined,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
  error: false,
};

export default SelectControl;
