import controlComponent from './RichTextControl';
import previewComponent from './RichTextPreview';

const Widget = (opts = {}) => ({
  name: 'richText',
  controlComponent,
  previewComponent,
  ...opts,
});

export const CmsWidgetRichText = {
  Widget,
  controlComponent,
  previewComponent,
};
export default CmsWidgetRichText;
