import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const DateTimePreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>
    {value ? value.toString() : null}
  </WidgetPreviewContainer>
);

DateTimePreview.propTypes = {
  value: PropTypes.object,
  field: PropTypes.object.isRequired,
};

DateTimePreview.defaultProps = {
  value: undefined,
};

export default DateTimePreview;
