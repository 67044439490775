import {gql} from '@apollo/client';

const fragments = {
  entry: gql`
    fragment ArticleTagEntry on ArticleTag {
      tag
      slug
      articleCount
    }
  `,
};

const queries = {
  list: gql`
    {
      findArticleTags {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          itemCount
          totalCount
        }
        nodes {
          ...ArticleTagEntry
        }
      }
    }
    ${fragments.entry}
  `,
  get: gql`
    query getArticleTag($id: ID!) {
      getArticleTag(id: $id) {
        ...ArticleTagEntry
      }
    }
    ${fragments.entry}
  `,
};

const ArticleTagGql = {fragments, queries};
export default ArticleTagGql;
