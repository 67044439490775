import PropTypes from 'prop-types';
import {Button, Icon, Menu} from 'semantic-ui-react';
import {Link} from 'gatsby';
import React, {isValidElement} from 'react';

const ToolbarActionItem = ({
  className,
  label,
  icon,
  link,
  onClick,
  ...rest
}) => {
  const clickHandler = link ? null : onClick;

  if (icon) {
    // icon menu item
    return (
      <Menu.Item
        className="datagridmenu__menu_item:icon"
        as={link ? Link : undefined}
        to={link}
        onClick={clickHandler}
        {...rest}>
        {isValidElement(icon) ? icon : <Icon name={icon} />}
        {label}
      </Menu.Item>
    );
  }

  // as button
  return (
    <Menu.Item className="datagridmenu__menu_item:button">
      <Button
        as={link ? Link : undefined}
        to={link}
        size="medium"
        onClick={clickHandler}
        {...rest}>
        {label}
      </Button>
    </Menu.Item>
  );
};

ToolbarActionItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  link: PropTypes.string,
  onClick: PropTypes.func,
};

ToolbarActionItem.defaultProps = {
  className: undefined,
  label: undefined,
  icon: undefined,
  link: undefined,
  onClick: undefined,
};

export default ToolbarActionItem;
