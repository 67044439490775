import controlComponent from './FileControl';
import previewComponent from './FilePreview';

const Widget = (opts = {}) => ({
  name: 'file',
  controlComponent,
  previewComponent,
  ...opts,
});

export const CmsWidgetFile = {
  Widget,
  controlComponent,
  previewComponent,
};
export default CmsWidgetFile;
