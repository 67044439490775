import React from 'react';
import {Divider, Form, Icon, Message, Modal} from 'semantic-ui-react';
import useSubscribe from '../../hooks/use-subscribe';

const SubscribeModal = ({header, campaign, ...props}) => {
  const {
    setName,
    setEmail,
    error,
    success,
    performSubscribe,
    subscribing,
  } = useSubscribe({
    campaign: campaign?.length ? campaign : 'Subscribe Modal',
  });

  const handleSubmit = e => {
    e.preventDefault();
    performSubscribe();
  };
  const handleNameChange = e => setName(e.currentTarget.value);
  const handleEmailChange = e => setEmail(e.currentTarget.value);

  const disabled = !!success;
  return (
    <Modal closeIcon size="small" {...props}>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>
        <div className="text-centered">
          <Icon name="envelope outline" size="huge" color="grey" />
          <p>The latest News In Your Inbox Every Month</p>
          <Divider section />
        </div>
        <div className="text-centered">
          {error && <Message compact negative content={error} />}
          {success && <Message compact success content={success} />}
        </div>
        <Form size="large" disabled={disabled} onSubmit={handleSubmit}>
          <Form.Input
            type="text"
            label="Your name"
            name="name"
            disabled={disabled}
            onChange={handleNameChange}
          />
          <Form.Input
            type="email"
            label="Your email address"
            name="email"
            disabled={disabled}
            onChange={handleEmailChange}
          />
          <Form.Button
            className="big-tm"
            primary
            fluid
            disabled={disabled}
            loading={subscribing}
            type="submit">
            Keep Up to Date on the Latest News
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default SubscribeModal;
