import controlComponent from './BooleanControl';
import previewComponent from './BooleanPreview';

const Widget = (opts = {}) => ({
  name: 'boolean',
  controlComponent,
  previewComponent,
  ...opts,
});

export const CmsWidgetBoolean = {
  Widget,
  controlComponent,
  previewComponent,
};
export default CmsWidgetBoolean;
