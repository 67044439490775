import {gql} from '@apollo/client';

const fragments = {
  entry: gql`
    fragment UnsplashPhotoEntry on UnsplashPhoto {
      id
      tags {
        title
      }
      urls {
        raw
        full
        regular
        small
        thumb
      }
      user {
        id
        username
        name
        links {
          html
        }
      }
    }
  `,
};

const queries = {
  search: gql`
    query searchUnsplashPhotos(
      $searchTerm: String!
      $orientation: UnsplashPhotoOrientation
    ) {
      searchUnsplashPhotos(
        filter: {query: $searchTerm, orientation: $orientation}
      ) {
        pageInfo {
          page
          perPage
          itemCount
          totalCount
          totalPages
        }
        nodes {
          ...UnsplashPhotoEntry
        }
      }
    }
    ${fragments.entry}
  `,
  get: gql`
    query getUnsplashPhoto($id: ID!) {
      getUnsplashPhoto(id: $id) {
        ...UnsplashPhotoEntry
      }
    }
    ${fragments.entry}
  `,
};

const UnsplashPhotoGql = {fragments, queries};
export default UnsplashPhotoGql;
