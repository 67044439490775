import {Quill} from 'react-quill';
import {isPlainObject} from '../../../../utils';

// See: https://github.com/quilljs/quill/blob/develop/formats/image.js
const EmbedBlot = Quill.import('blots/embed');

const ATTRIBUTES = {
  url: {
    attr: 'src',
    sanitize: true,
  },
  alt: {
    attr: 'alt',
  },
  title: {
    attr: 'title',
  },
  attachmentId: {
    attr: 'data-attachment-id',
  },
  slug: {
    attr: 'data-slug',
  },
  height: {
    attr: 'height',
  },
  width: {
    attr: 'width',
  },
  origHeight: {
    attr: 'data-orig-height',
  },
  origWidth: {
    attr: 'data-orig-width',
  },
  style: {
    attr: 'style',
  },
};

// Copied from 'quill/formats/link' sanitize function
const linkSanitize = (url, protocols) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
};

class ImageBlot extends EmbedBlot {
  static create(value) {
    const node = super.create(value);
    if (isPlainObject(value)) {
      Object.entries(value).forEach(([prop, propValue]) => {
        const spec = ATTRIBUTES[prop];
        if (spec) {
          const attrVal = spec.sanitize ? this.sanitize(propValue) : propValue;
          node.setAttribute(spec.attr, attrVal);
        }
      });
    }

    return node;
  }

  static value(domNode) {
    return Object.entries(ATTRIBUTES).reduce((value, [attribute, spec]) => {
      if (domNode.hasAttribute(spec.attr)) {
        value[attribute] = domNode.getAttribute(spec.attr);
      }
      return value;
    }, {});
  }

  static formats(domNode) {
    return Object.entries(ATTRIBUTES).reduce((formats, [attribute, spec]) => {
      if (domNode.hasAttribute(spec.attr)) {
        formats[attribute] = domNode.getAttribute(spec.attr);
      }
      return formats;
    }, {});
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static register() {
    if (/Firefox/i.test(navigator.userAgent)) {
      setTimeout(() => {
        // Disable image resizing in Firefox
        document.execCommand('enableObjectResizing', false, 'false');
      }, 1);
    }
  }

  static sanitize(url) {
    return linkSanitize(url, ['http', 'https', 'data']) ? url : '//:0';
  }

  format(name, value) {
    const spec = Object.prototype.hasOwnProperty.call(ATTRIBUTES, name)
      ? ATTRIBUTES[name]
      : null;
    if (spec) {
      if (value) {
        this.domNode.setAttribute(
          spec.attr,
          spec.sanitize ? ImageBlot.sanitize(value) : value,
        );
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'IMG';

export default ImageBlot;
