import React, {useCallback, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ReactQuill, {Quill} from 'react-quill';
import quillStyles from 'react-quill/dist/quill.snow.css';
import {quillModules} from '../../../quill';

import EditorMediaPicker from '../../EditorMediaPicker';

const addAImageAttachmentToEditor = (quill, attachment) => {
  const range = quill.getSelection(true);
  const value = {
    url: attachment.urls.regular || attachment.urls.original,
    attachmentId: attachment.id,
    title: attachment.title,
    slug: attachment.slug,
    width: '100%',
    height: '100%',
  };
  if (attachment.altText) {
    value.alt = attachment.altText;
  }
  // if (data.content.metadata.width) {
  //   value.origWidth = data.content.metadata.width;
  // }
  // if (data.content.metadata.height) {
  //   value.origHeight = data.content.metadata.height;
  // }

  quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
  quill.setSelection(range.index + 1, 0, Quill.sources.SILENT);
};

const addAttachmentToEditor = (quill, attachment) => {
  switch (attachment.attachmentType) {
    case 'IMAGE':
      addAImageAttachmentToEditor(quill, attachment);
      break;

    default:
      // eslint-disable-next-line no-alert
      alert(
        `Inserting ${attachment.attachmentType.toLowerCase()} attachments not yet supported`,
      );
  }
};

const sanitizeEditorValue = value => {
  if (value.endsWith('\n\n')) {
    // eslint-disable-next-line no-param-reassign
    value = value.substring(0, value.length - 2);
  }

  return value;
};

const EditorContainer = styled.div`
  p {
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
`;

const RichTextControl = ({
  id,
  value,
  placeholder,
  disabled,
  onChange,
  onBlur,
  onFocus,
  autoFocus,
}) => {
  const quillRef = useRef(null);
  const [pickMedia, setPickMedia] = useState(false);

  const modules = useMemo(() => {
    const overrides = {
      toolbar: {
        handlers: {
          image: () => setPickMedia(true),
        },
      },
    };

    return quillModules({reactQuillRef: quillRef, overrides});
  }, [quillRef, setPickMedia]);

  const handleOnSaveAttachment = useCallback(
    ({selectedItems}) => {
      const entries = selectedItems ? Object.entries(selectedItems) : [];
      const quill = quillRef.current ? quillRef.current.getEditor() : null;
      if (quill && entries.length) {
        const [, attachment] = entries[0]; // expecting only one attachment
        addAttachmentToEditor(quill, attachment);
      }

      setPickMedia(false);
    },
    [quillRef, setPickMedia],
  );

  return (
    <EditorContainer
      css={css`
        ${quillStyles}
      `}>
      <ReactQuill
        ref={quillRef}
        id={id}
        theme="snow"
        modules={modules}
        value={value || ''}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(newValue, delta, source, editor) => {
          onChange(sanitizeEditorValue(newValue), {delta, source, editor});
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
      />
      {pickMedia && (
        // New image picker instance every time, so no context is maintained
        <EditorMediaPicker
          open
          onSave={handleOnSaveAttachment}
          commitOnSelect
        />
      )}
    </EditorContainer>
  );
};

RichTextControl.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
};

RichTextControl.defaultProps = {
  value: undefined,
  autoFocus: false,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
  placeholder: undefined,
};

export default RichTextControl;
