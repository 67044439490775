import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const StyledImage = styled(({src}) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img src={src || ''} role="presentation" />
))`
  display: block;
  max-width: 100%;
  height: auto;
`;

const StyledImageAsset = ({getAsset, value, field}) => (
  <StyledImage src={getAsset(value, field)} />
);

const ImagePreviewContent = props => {
  const {value, getAsset, field} = props;
  if (Array.isArray(value)) {
    return value.map(val => (
      <StyledImageAsset
        key={val}
        value={val}
        getAsset={getAsset}
        field={field}
      />
    ));
  }

  return <StyledImageAsset {...props} />;
};

const MediaPreview = props => {
  const {value, field} = props;
  return (
    <WidgetPreviewContainer label={field.label}>
      {value ? <ImagePreviewContent {...props} /> : null}
    </WidgetPreviewContainer>
  );
};

MediaPreview.propTypes = {
  getAsset: PropTypes.func.isRequired,
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};

MediaPreview.defaultProps = {
  value: undefined,
};

export default MediaPreview;
