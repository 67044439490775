import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const RelationPreview = ({value}) => (
  <WidgetPreviewContainer>{value}</WidgetPreviewContainer>
);

RelationPreview.propTypes = {
  value: PropTypes.node,
};

RelationPreview.defaultProps = {
  value: undefined,
};
export default RelationPreview;
