import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const NumberPreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>{value}</WidgetPreviewContainer>
);

NumberPreview.propTypes = {
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};

NumberPreview.defaultProps = {
  value: undefined,
};
export default NumberPreview;
