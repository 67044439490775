/* eslint-disable import/prefer-default-export */
import {Quill} from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
// import quillAutoFormat from 'quill-autoformat';
import MagicUrl from 'quill-magic-url';
import merge from 'lodash.merge';
import ImageBlot from './ImageBlot';
import VideoFormat from './VideoFormat';
import SmartBreak from './SmartBreakBlot';

const Delta = Quill.import('delta');

Quill.register(ImageBlot, true /* suppressWarning */);
Quill.register(VideoFormat, true /* suppressWarning */);
Quill.register(SmartBreak, true /* suppressWarning */);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/magicUrl', MagicUrl);

const createToolbarModule = () => ({
  container: [
    [{header: [1, 2, 3, 4, 5, 6, false]}, {header: 1}, {header: 2}],
    [{font: []}, {size: ['small', false, 'large', 'huge']}],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    [{align: []}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    // [{ direction: 'rtl' }], // text direction
    [{color: []}, {background: []}], // dropdown with defaults from theme
    ['link', 'image', 'video'],
    ['clean'], // remove formatting button
  ],
});

// For line break (via Shift+Enter):
// * https://codepen.io/mackermedia/pen/gmNwZP
// * https://github.com/quilljs/quill/issues/252#issuecomment-292565427

const lineBreakMatcher = () => {
  const newDelta = new Delta();
  newDelta.insert({break: ''});
  return newDelta;
};

const createClipboardModule = () => ({
  matchers: [['BR', lineBreakMatcher]],
});

const createKeyboardModule = reactQuillRef => ({
  bindings: {
    linebreak: {
      key: 13, // Enter
      shiftKey: true,
      handler: range => {
        const quill = reactQuillRef.current
          ? reactQuillRef.current.getEditor()
          : null;
        if (!quill) return;

        const currentLeaf = quill.getLeaf(range.index)[0];
        const nextLeaf = quill.getLeaf(range.index + 1)[0];

        quill.insertEmbed(range.index, 'break', true, Quill.sources.USER);

        // Insert a second break if:
        // At the end of the editor, OR next leaf has a different parent (<p>)
        if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
          quill.insertEmbed(range.index, 'break', true, Quill.sources.USER);
        }

        // Now that we've inserted a line break, move the cursor forward
        quill.setSelection(range.index + 1, 0, Quill.sources.SILENT);
      },
    },
  },
});

export const quillModules = ({reactQuillRef, overrides} = {}) => {
  const modules = {
    toolbar: createToolbarModule(),
    clipboard: createClipboardModule(),
    keyboard: createKeyboardModule(reactQuillRef),
    blotFormatter: {},
    magicUrl: true,
  };

  if (overrides) {
    merge(modules, overrides);
  }

  return modules;
};
