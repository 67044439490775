import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react';

const MediaTypeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 0.25rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
`;

const MediaTypeIndicator = ({mediaType}) => {
  let icon;
  switch (mediaType) {
    case 'IMAGE':
      icon = 'file image outline';
      break;
    case 'VIDEO':
      icon = 'file video outline';
      break;
    case 'DOCUMENT':
      icon = 'file pdf outline';
      break;
    default:
      icon = null;
      break;
  }
  return (
    icon && (
      <MediaTypeContainer>
        <Icon name={icon} size="large" />
      </MediaTypeContainer>
    )
  );
};

MediaTypeIndicator.propTypes = {
  mediaType: PropTypes.string,
};

MediaTypeIndicator.defaultProps = {
  mediaType: undefined,
};

export default MediaTypeIndicator;
