import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const FileLink = styled(({href, path}) => (
  <a href={href} rel="noopener noreferrer" target="_blank">
    {path}
  </a>
))`
  display: block;
`;

const FileLinkList = ({values, getAsset, field}) => (
  <div>
    {values.map(value => (
      <FileLink key={value} path={value} href={getAsset(value, field)} />
    ))}
  </div>
);

const FileContent = ({value, getAsset, field}) => {
  if (Array.isArray(value)) {
    return <FileLinkList values={value} getAsset={getAsset} field={field} />;
  }

  return <FileLink key={value} path={value} href={getAsset(value, field)} />;
};

const FilePreview = props => {
  const {value, field} = props;
  return (
    <WidgetPreviewContainer label={field.label}>
      {value ? <FileContent {...props} /> : null}
    </WidgetPreviewContainer>
  );
};

FilePreview.propTypes = {
  getAsset: PropTypes.func.isRequired,
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};

FilePreview.defaultProps = {
  value: undefined,
};

export default FilePreview;
