// eslint-disable-next-line no-unused-vars
import React from 'react';
import {observer} from 'mobx-react-lite';
import {navigate} from 'gatsby';
import {Container, Dropdown, Menu} from 'semantic-ui-react';

import logo from '../../../images/logo.png';
import useStore from '../../../hooks/use-store';
import useSiteSettings from '../../../hooks/use-site-settings';

const AdminHeader = observer(() => {
  const authStore = useStore('authStore');
  const siteSettings = useSiteSettings();
  const logout = async () => {
    await authStore.logout();
    await navigate('/');
  };

  return (
    <Container className="admin__header" fluid>
      <Menu className="fixed top" icon borderless>
        <Menu.Item>
          <img src={logo} alt={siteSettings.siteTitle} />
          <div className="text-bold small-lm">{siteSettings.siteTitle}</div>
        </Menu.Item>
        <Menu.Menu position="right">
          <Dropdown item text={authStore.user.firstName}>
            <Dropdown.Menu>
              <Dropdown.Item icon="sign-out" text="Logout" onClick={logout} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    </Container>
  );
});

export default AdminHeader;
