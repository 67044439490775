import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Tab} from 'semantic-ui-react';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const TabContent = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const RichTextPreview = ({value, field}) => {
  const panes = useMemo(
    () => [
      {
        menuItem: 'Preview',
        render: () => (
          <Tab.Pane
            as={TabContent}
            attached={false}
            dangerouslySetInnerHTML={{__html: value}}
          />
        ),
      },
      {
        menuItem: 'HTML',
        render: () => (
          <Tab.Pane as={TabContent} attached={false}>
            {value}
          </Tab.Pane>
        ),
      },
    ],
    [value],
  );

  return (
    <WidgetPreviewContainer label={field.label}>
      <Tab menu={{secondary: true, pointing: true}} panes={panes} />
    </WidgetPreviewContainer>
  );
};

RichTextPreview.propTypes = {
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};

RichTextPreview.defaultProps = {
  value: undefined,
};
export default RichTextPreview;
