import {gql} from '@apollo/client';

const attachmentFields = `
      id
      createdAt
      updatedAt
      attachmentType
      title
      slug
      caption
      altText
      description
      urls
      previewImageUrl
      contentData
`;

const fragments = {
  entry: gql`
    fragment AttachmentEntry on Attachment {
      ${attachmentFields}
    }
  `,
  articleAttachmentEntry: gql`
    fragment ArticleAttachmentEntry on ArticleAttachment {
      ${attachmentFields}
      isFeature
    }
  `,
  createEntry: gql`
    fragment CreateAttachmentEntry on AttachmentCreate {
      title
      caption
      altText
      description
      external {
        storage
        id
        url
        urls
        user
        service
        previewImageUrl
      }
    }
  `,
  uploadEntry: gql`
    fragment UploadAttachmentEntry on AttachmentUpload {
      title
      caption
      altText
      description
      upload
    }
  `,
};

const queries = {
  list: gql`
    {
      findAttachments {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          itemCount
          totalCount
        }
        nodes {
          ...AttachmentEntry
        }
      }
    }
    ${fragments.entry}
  `,
  get: gql`
    query getAttachment($id: ID!) {
      getAttachment(id: $id) {
        ...AttachmentEntry
      }
    }
    ${fragments.entry}
  `,
  create: gql`
    mutation createAttachment($data: AttachmentCreate!) {
      createAttachment(data: $data) {
        ...AttachmentEntry
      }
    }
    ${fragments.entry}
  `,
  upload: gql`
    mutation uploadAttachment($data: AttachmentUpload!) {
      uploadAttachment(data: $data) {
        ...AttachmentEntry
      }
    }
    ${fragments.entry}
  `,
  update: gql`
    mutation updateAttachment($id: ID!, $data: AttachmentUpdate!) {
      updateAttachment(id: $id, data: $data) {
        ...AttachmentEntry
      }
    }
    ${fragments.entry}
  `,
  delete: gql`
    mutation deleteAttachment($id: ID!) {
      deleteAttachment(id: $id) {
        ...AttachmentEntry
      }
    }
    ${fragments.entry}
  `,
};

const getCached = cache => {
  const cacheEntry = cache.readQuery({query: queries.list});
  return {
    cacheEntry,
    attachments: cacheEntry?.findAttachments?.nodes,
  };
};

const updateCache = (cache, record, updatedData) => {
  const {cacheEntry} = record;
  cacheEntry.findAttachments.data = updatedData;
  cacheEntry.findAttachments.total = updatedData.length;
  cache.writeQuery({query: queries.list, data: cacheEntry});
};

const ops = {
  updateCachePostCreate: (cache, fetchResult) => {
    const record = getCached(cache);
    const {attachments} = record;
    if (attachments) {
      const {
        data: {createAttachment: newAttachment},
      } = fetchResult;
      const updatedData = [newAttachment, ...attachments];
      updateCache(cache, record, updatedData);
    }
  },
  updateCachePostDelete: (cache, fetchResult) => {
    const record = getCached(cache);
    const {attachments} = record;
    if (attachments) {
      const {
        data: {
          deleteAttachment: {id},
        },
      } = fetchResult;
      const updatedData = attachments.filter(c => c.id !== id);
      updateCache(cache, record, updatedData);
    }
  },
};

const AttachmentGql = {fragments, queries, ops};
export default AttachmentGql;
