import React from 'react';
import {observer} from 'mobx-react-lite';
import {navigate} from 'gatsby';
import Loading from '../Loading';
import useStore from '../../../hooks/use-store';

const AdminContainer = observer(({children, location}) => {
  const {pathname = '/admin'} = location || {};
  const authStore = useStore('authStore');

  if (authStore.isAuthenticating) {
    return <Loading />;
  }

  if (!authStore.isAuthenticated) {
    navigate('/login', {
      state: {asAdmin: true, redirectTo: pathname},
    });
    return null;
  }

  if (!authStore.canAccessAdmin) {
    navigate('/', {replace: true});
    return null;
  }

  return <div className="admin">{children}</div>;
});

export default AdminContainer;
