import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const StringPreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>{value}</WidgetPreviewContainer>
);

StringPreview.propTypes = {
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};

StringPreview.defaultProps = {
  value: undefined,
};
export default StringPreview;
