import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const BooleanPreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>
    {value ? 'YES' : 'NO'}
  </WidgetPreviewContainer>
);

BooleanPreview.propTypes = {
  value: PropTypes.bool,
  field: PropTypes.object.isRequired,
};

BooleanPreview.defaultProps = {
  value: false,
};
export default BooleanPreview;
