import React from 'react';
import {Menu} from 'semantic-ui-react';
import classNames from 'classnames';

const Toolbar = ({children, className, ...rest}) => (
  <Menu
    className={classNames('datagridmenu', className)}
    borderless
    compact
    text
    size="tiny"
    icon="labeled"
    {...rest}>
    {children}
  </Menu>
);

export default Toolbar;
