import React from 'react';
import styled from 'styled-components';
import {colors, transitions} from '../../../../../theme';

const Container = styled.div`
  margin: 15px;
`;

const FieldLabel = styled.label`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${colors.controlLabel};
  background-color: ${colors.textFieldBorder};
  display: inline-block;
  border: 0;
  border-radius: 3px 3px 0 0;
  padding: 3px 6px 2px;
  margin: 0;
  transition: all ${transitions.main};
  position: relative;
  /**
   * Faux outside curve into top of input
   */
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -4px;
    height: 100%;
    width: 4px;
    background-color: inherit;
  }
  &:after {
    border-bottom-left-radius: 3px;
    background-color: #fff;
  }
`;

const ContentContainer = styled.div`
  padding: 10px;
  border: 2px solid ${colors.textFieldBorder};
`;

const WidgetPreviewContainer = ({label, children}) => (
  <Container>
    {label && <FieldLabel>{label}</FieldLabel>}
    <ContentContainer>{children}</ContentContainer>
  </Container>
);
export default WidgetPreviewContainer;
