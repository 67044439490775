import {getApolloClient} from '../../../../../apollo/client';
import ArticleCategoryGql from '../../../../../gql/frontend/ArticleCategoryGql';

const fetchArticleCategories = async () => {
  const apolloClient = getApolloClient();
  const result = await apolloClient.query({
    query: ArticleCategoryGql.queries.list,
    fetchPolicy: 'cache-first',
  });

  const {
    data: {
      findArticleCategories: {nodes: categories},
    },
  } = result;

  // Apollo results are frozen so must copy array before sorting
  return [...categories].sort((a, b) => a.title > b.title);
};

export default fetchArticleCategories;
