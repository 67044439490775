import {Quill} from 'react-quill';

// Expands the video format to include styles
// See:
//   * https://github.com/quilljs/quill/blob/develop/formats/video.js
//   * https://github.com/koan00/quill-image-resize-module/commit/95ba869e4045d267062c3c01091c121141f7004d
const BaseVideoFormat = Quill.import('formats/video');

const ATTRIBUTES = ['alt', 'width', 'height', 'style'];

class VideoFormat extends BaseVideoFormat {
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attr) => {
      if (domNode.hasAttribute(attr)) {
        formats[attr] = domNode.getAttribute(attr);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    if (ATTRIBUTES.includes(name)) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

export default VideoFormat;
