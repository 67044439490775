/* eslint-disable react/no-unused-prop-types */
import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Image, Input, Modal} from 'semantic-ui-react';
import dvr from 'mobx-react-form/lib/validators/DVR';
import styled from 'styled-components';
import validatorjs from 'validatorjs';
import {observer} from 'mobx-react-lite';
import MobxReactForm from 'mobx-react-form';
import logger from '../../../../logger';

const createReactForm = ({
  title,
  caption,
  altText,
  description,
  onSave,
  onError,
  setFormErrors,
}) => {
  const fields = [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'Important. Drives SEO',
      default: title,
      value: title,
      rules: 'required|string',
    },
    {
      name: 'caption',
      label: 'Caption',
      placeholder: 'Caption text under image. Short description here',
      default: caption,
      value: caption,
      rules: 'string',
    },
    {
      name: 'altText',
      label: 'Alt Text',
      placeholder: 'Text show when image fails to display',
      default: altText,
      value: altText,
      rules: 'string',
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Detailed description of what the image is about',
      default: description,
      value: description,
      rules: 'string',
    },
  ];

  const plugins = {
    dvr: dvr(validatorjs),
  };
  const hooks = {
    onSuccess: form => {
      const values = form.values();
      logger.debug('Dispatching form values');
      logger.debug(values);

      onSave({values, fields});
    },
    onError: form => {
      const errors = form.errors();
      logger.warn('Form has errors');
      logger.warn(errors);

      setFormErrors(errors);
      if (onError) onError({errors, fields});
    },
  };

  return new MobxReactForm({fields}, {plugins, hooks});
};

const StyledFormContainer = styled(Modal.Description)`
  flex: 1 1 auto !important;
`;

const FormInput = ({reactField}) => {
  const {
    id,
    label,
    placeholder,
    value,
    error,
    onBlur,
    onChange,
    onFocus,
  } = reactField;
  const required = reactField.rules.includes('required');
  return (
    <Form.Field required={required} error={error}>
      <label htmlFor={id}>{label}</label>
      <Input
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </Form.Field>
  );
};

const ObservingFormInput = observer(FormInput);

const EditMediaInfoModal = props => {
  const {previewImageUrl, title, open, onCancel} = props;

  const [formErrors, setFormErrors] = useState(null);
  const reactForm = useMemo(() => createReactForm({...props, setFormErrors}), [
    props,
    setFormErrors,
  ]);

  const formHasError =
    formErrors && Object.entries(formErrors).find(([_, err]) => !!err);
  return (
    <Modal open={open}>
      <Modal.Header>Editing {title || 'Image'}</Modal.Header>
      <Modal.Content image>
        <Image wrapped size="medium" src={previewImageUrl} />
        <StyledFormContainer>
          <Form onSubmit={reactForm.onSubmit} error={formHasError}>
            {['title', 'caption', 'altText', 'description'].map(name => {
              const field = reactForm.$(name);
              return <ObservingFormInput key={name} reactField={field} />;
            })}
            <div className="large-tm">
              <Button
                className="baseline-rm"
                basic
                floated="right"
                onClick={ev => {
                  ev.preventDefault();
                  onCancel();
                }}>
                Cancel
              </Button>
              <Button
                color="teal"
                floated="right"
                onClick={ev => {
                  ev.preventDefault();
                  reactForm.submit();
                }}>
                Save changes
              </Button>
            </div>
          </Form>
        </StyledFormContainer>
      </Modal.Content>
    </Modal>
  );
};

EditMediaInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  previewImageUrl: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func,
  title: PropTypes.string,
  caption: PropTypes.string,
  altText: PropTypes.string,
  description: PropTypes.string,
};

EditMediaInfoModal.defaultProps = {
  title: null,
  caption: null,
  altText: null,
  description: null,
  onError: undefined,
};

export default EditMediaInfoModal;
