import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const ListPreview = ({values}) => (
  <ul>
    {values.map((value, idx) => (
      <li key={idx}>{value}</li>
    ))}
  </ul>
);

const SelectPreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>
    {value && (Array.isArray(value) ? <ListPreview values={value} /> : value)}
  </WidgetPreviewContainer>
);

SelectPreview.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  field: PropTypes.object.isRequired,
};

SelectPreview.defaultProps = {
  value: undefined,
};
export default SelectPreview;
