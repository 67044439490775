import React, {useCallback, useMemo, useState} from 'react';
import {useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import {Button, Embed, Form, Image, Input, Modal} from 'semantic-ui-react';
import dvr from 'mobx-react-form/lib/validators/DVR';
import styled from 'styled-components';
import validatorjs from 'validatorjs';
import getVideoInfo from 'get-video-id';
import {observer} from 'mobx-react-lite';
import MobxReactForm from 'mobx-react-form';
import logger from '../../../../logger';
import placeholderImage from './video_placeholder.png';
import AttachmentGql from '../../../../gql/frontend/AttachmentGql';
import ErrorHandler from '../../ErrorHandler';

const createReactForm = ({
  url,
  title,
  caption,
  onSave,
  onError,
  setFormErrors,
}) => {
  const fields = [
    {
      name: 'url',
      label: 'Url',
      placeholder: 'Paste YouTube video url here',
      default: url,
      value: url,
      rules: 'required|url',
    },
    {
      name: 'title',
      label: 'Title',
      placeholder: 'Important. Drives SEO',
      default: title,
      value: title,
      rules: 'required|string',
    },
    {
      name: 'caption',
      label: 'Caption',
      placeholder: 'Caption text under image. Short description here',
      default: caption,
      value: caption,
      rules: 'string',
    },
    {
      name: 'service',
      rules: 'string',
    },
    {
      name: 'id',
      rules: 'string',
    },
    {
      name: 'previewImageUrl',
      rules: 'string',
    },
  ];

  const plugins = {
    dvr: dvr(validatorjs),
  };
  const hooks = {
    onSuccess: form => {
      const values = form.values();
      logger.debug('Dispatching form values');
      logger.debug(values);

      onSave({values, fields});
    },
    onError: form => {
      const errors = form.errors();
      logger.warn('Form has errors');
      logger.warn(errors);

      setFormErrors(errors);
      if (onError) onError({errors, fields});
    },
  };

  return new MobxReactForm({fields}, {plugins, hooks});
};

const getPreviewImage = ({service, id}) => {
  if (service === 'youtube') {
    // https://coderwall.com/p/nihgwq/get-a-thumbnail-from-a-youtube-video
    return `https://img.youtube.com/vi/${id}/0.jpg`;
  }

  return null;
};

const StyledEmbedContainer = styled.div`
  width: 50%;
  margin-right: 20px;
`;

const StyledFormContainer = styled(Modal.Description)`
  flex: 1 1 auto !important;
`;

const FormInput = ({reactField, onBlurExtended}) => {
  const {
    id,
    label,
    placeholder,
    value,
    error,
    disabled,
    onBlur,
    onChange,
    onFocus,
  } = reactField;
  const required = reactField.rules.includes('required');
  return (
    <Form.Field required={required} error={error}>
      <label htmlFor={id}>{label}</label>
      <Input
        id={id}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={ev => {
          if (onBlur) onBlur(ev);
          if (onBlurExtended) onBlurExtended(ev);
        }}
        onFocus={onFocus}
      />
    </Form.Field>
  );
};

const ObservingFormInput = observer(FormInput);

const EmbedVideoModal = props => {
  const {title, open, onCancel, onSave} = props;

  const [embedProps, setEmbedProps] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [createAttachment, {error}] = useMutation(AttachmentGql.queries.create);

  const handleSaveVideo = useCallback(
    ({values}) => {
      const {title: titleValue, caption, ...rest} = values;
      const external = {storage: 'OEMBED', ...rest};
      const data = {title: titleValue, caption, external};
      createAttachment({
        variables: {data},
      }).then(({data: {createAttachment: attachmentData}}) =>
        onSave(attachmentData),
      );
    },
    [createAttachment, onSave],
  );

  const reactForm = useMemo(
    () => createReactForm({...props, setFormErrors, onSave: handleSaveVideo}),
    [setFormErrors, handleSaveVideo, props],
  );

  const getVideoProps = useCallback(() => {
    const videoUrl = reactForm.$('url').value;
    if (!videoUrl) return;

    const videoInfo = getVideoInfo(videoUrl);
    if (videoInfo.id && videoInfo.service) {
      const {id, service} = videoInfo;
      const previewImageUrl = getPreviewImage({service, id});
      reactForm.$('id').set(id);
      reactForm.$('service').set(service);
      reactForm.$('previewImageUrl').set(previewImageUrl);
      setEmbedProps({
        id,
        source: service,
        placeholder: previewImageUrl,
      });
    } else {
      setEmbedProps(null);
    }
  }, [setEmbedProps, reactForm]);

  const formHasError =
    formErrors && Object.entries(formErrors).find(([_, err]) => !!err);
  return (
    <Modal open={open}>
      <Modal.Header>{title ? `Editing ${title}` : 'Select Video'}</Modal.Header>
      {error && <ErrorHandler error={error} title="Error saving video" />}
      <Modal.Content image>
        {embedProps ? (
          <StyledEmbedContainer>
            <Embed {...embedProps} />
          </StyledEmbedContainer>
        ) : (
          <Image wrapped size="medium" src={placeholderImage} />
        )}
        <StyledFormContainer>
          <Form onSubmit={reactForm.onSubmit} error={formHasError}>
            {['url', 'title', 'caption'].map(name => {
              const field = reactForm.$(name);
              return name === 'url' ? (
                <ObservingFormInput
                  key={name}
                  reactField={field}
                  onBlurExtended={getVideoProps}
                />
              ) : (
                <ObservingFormInput key={name} reactField={field} />
              );
            })}
            <div className="large-tm">
              <Button
                className="baseline-rm"
                basic
                floated="right"
                onClick={ev => {
                  ev.preventDefault();
                  onCancel();
                }}>
                Cancel
              </Button>
              <Button
                color="teal"
                floated="right"
                disabled={!embedProps}
                onClick={ev => {
                  ev.preventDefault();
                  reactForm.submit();
                }}>
                Save changes
              </Button>
            </div>
          </Form>
        </StyledFormContainer>
      </Modal.Content>
    </Modal>
  );
};

EmbedVideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  url: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onError: PropTypes.func,
  title: PropTypes.string,
  caption: PropTypes.string,
};

EmbedVideoModal.defaultProps = {
  title: null,
  caption: null,
  onError: undefined,
};

export default observer(EmbedVideoModal);
