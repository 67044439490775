import React from 'react';
import {navigate} from 'gatsby';
import Status from './Status';
import {isGqlAuthError} from '../../apollo/client';
import useStore from '../../hooks/use-store';

const ErrorHandler = ({className, error, title, location, asModal}) => {
  const authStore = useStore('authStore');
  const {pathname = '/admin'} = location || window.location;

  if (!error || (Array.isArray(error) && !error.length)) return null;
  const errors = Array.isArray(error) ? error : [error];

  if (errors.some(isGqlAuthError)) {
    authStore.logout().then(() =>
      navigate('/login', {
        state: {asAdmin: true, redirectTo: pathname},
      }),
    );
    return null;
  }

  return (
    <Status
      className={className}
      errors={errors}
      header={title}
      asModal={asModal}
    />
  );
};

export default ErrorHandler;
