import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'semantic-ui-react';

const StringControl = ({
  id,
  value,
  placeholder,
  disabled,
  onChange,
  onBlur,
  onFocus,
  autoFocus,
}) => (
  <Input
    id={id}
    value={value || ''}
    placeholder={placeholder}
    disabled={disabled}
    onChange={(ev, data) => {
      onChange(ev.target.value, {data});
    }}
    onBlur={onBlur}
    onFocus={onFocus}
    autoFocus={autoFocus}
  />
);

StringControl.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
};

StringControl.defaultProps = {
  value: undefined,
  autoFocus: false,
  onFocus: undefined,
  onBlur: undefined,
  disabled: false,
  placeholder: undefined,
};

export default StringControl;
