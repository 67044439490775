import {getApolloClient} from '../../../../../apollo/client';
import ArticleTagGql from '../../../../../gql/frontend/ArticleTagGql';

const SUGGESTED_TAGS = [
  'Affordable housing',
  'Architecture',
  'Austin',
  'Before and after',
  'Buy',
  'Buyers market',
  'Bee Cave',
  'Buda',
  'Cedar Park',
  'Closing',
  'Condos',
  'Co-ops',
  'Design',
  'DIY',
  'Driftwood',
  'Dripping Springs',
  'Financing',
  'FSBO',
  'For sale by owner',
  'Georgetown',
  'Hutto',
  'Investing',
  'Improve',
  'Kids',
  'Kyle',
  'Lago Vista',
  'Lakeway',
  'Landlords',
  'Leander',
  'Liberty Hill',
  'Live',
  'Manor',
  'Negotiating',
  'Neighbors',
  'New construction',
  'Neighborhoods',
  'Non-traditional Buyer',
  'Open houses',
  'Pets',
  'Pflugerville',
  'Products and Services',
  'Rent',
  'Renovations',
  'Rental market',
  'Real estate search',
  'Roommates',
  'Round Rock',
  'Sell',
  'Sellers market',
  'Small spaces',
  'Staging',
];

const fetchArticleTags = async () => {
  const apolloClient = getApolloClient();
  const result = await apolloClient.query({
    query: ArticleTagGql.queries.list,
    fetchPolicy: 'cache-first',
  });

  const {
    data: {
      findArticleTags: {nodes: tags},
    },
  } = result;

  const allTags = tags.map(tag => tag.tag);
  allTags.push(...SUGGESTED_TAGS);
  return Array.from(new Set(allTags)).sort((a, b) => a.tag > b.tag);
};

export default fetchArticleTags;
