import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import {DashboardModal} from '@uppy/react';
import {Confirm} from 'semantic-ui-react';
import MediaModal from '../media/MediaModal';
import {attachmentUppy} from '../../../../uppy';
import logger from '../../../../logger';
import UnsplashPicker from '../unsplash/UnsplashPicker';
import EmbedVideoModal from '../embed_video/EmbedVideoModal';
import ErrorHandler from '../../ErrorHandler';
import DatagridLoading from '../datagrid/DatagridLoading';
import AttachmentGql from '../../../../gql/frontend/AttachmentGql';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

// eslint-disable-next-line no-alert
const NOT_YET_IMPLEMENTED = () => window.alert('Not yet implmented');
const UPLOAD_SOURCE_UPLOADS = 'uploads';
const UPLOAD_SOURCE_UNSPLASH = 'unsplash';
const UPLOAD_SOURCE_VIDEO = 'video';
const UPLOAD_SOURCES = [
  UPLOAD_SOURCE_UPLOADS,
  UPLOAD_SOURCE_UNSPLASH,
  UPLOAD_SOURCE_VIDEO,
];

const removeItemFromSelectedItems = (selectedItems, item) =>
  Object.keys(selectedItems).reduce((items, id) => {
    if (id !== item.id) items[id] = selectedItems[id];
    return items;
  }, {});

const selectedItemsFromDefaultValue = value => {
  if (value) {
    if (Array.isArray(value)) {
      return value.reduce((selectedItems, attachment) => {
        selectedItems[attachment.id] = attachment;
        return selectedItems;
      }, {});
    }
    return {[value.id]: value};
  }

  return {};
};

const itemFromResults = (id, results) => {
  const records = results?.findAttachments?.nodes;
  if (!(Array.isArray(records) && records.length)) return undefined;
  return records.find(({id: recordId}) => recordId === id);
};

const EditorMediaPicker = ({
  open,
  onSave,
  onClose,
  value,
  supportsMultiSelect,
  commitOnSelect,
}) => {
  // const [page] = useState(START_PAGE);
  const [selectedItems, setSelectedItems] = useState(
    selectedItemsFromDefaultValue(value),
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [openUppy, setOpenUppy] = useState(false);
  const [openUnsplash, setOpenUnsplash] = useState(false);
  const [openVideoPicker, setOpenVideoPicker] = useState(false);
  const [pendingDelete, setPendingDelete] = useState(null);
  const {loading, error: loadError, data, refetch} = useQuery(
    AttachmentGql.queries.list,
  );
  // const [createAttachment, { error: createError }] = useMutation(
  //   AttachmentGql.queries.create,
  // );
  // const [updateAttachment, { error: updateError }] = useMutation(
  //   AttachmentGql.queries.update,
  // );
  const [deleteAttachment, {error: deleteError}] = useMutation(
    AttachmentGql.queries.delete,
  );

  const handleSelect = useCallback(
    ({item}) => {
      let selected;
      if (selectedItems[item.id]) {
        selected = selectedItems;
      } else {
        if (supportsMultiSelect) {
          selected = {...selectedItems, [item.id]: item};
        } else {
          selected = {[item.id]: item};
        }
        setSelectedItems(selected);
      }

      if (commitOnSelect) {
        onSave({selectedItems: selected});
      }
    },
    [
      selectedItems,
      setSelectedItems,
      commitOnSelect,
      onSave,
      supportsMultiSelect,
    ],
  );

  const handleToggleSelect = useCallback(
    ({item}) => {
      const currentlySelected = !!selectedItems[item.id];

      if (currentlySelected) {
        // Then deselect
        setSelectedItems(removeItemFromSelectedItems(selectedItems, item));
      } else {
        handleSelect({item});
      }
    },
    [selectedItems, setSelectedItems, handleSelect],
  );
  const handleDeleteItem = useCallback(
    ({item, index}) => {
      const currentlySelected = !!selectedItems[item.id];

      if (currentlySelected) {
        // Then deselect
        setSelectedItems(removeItemFromSelectedItems(selectedItems, item));
      }

      // Now delete
      setIsDeleting(true);
      deleteAttachment({variables: {id: item.id}}).then(() => {
        setPendingDelete(null);
        setIsDeleting(false);
      });
    },
    [selectedItems, deleteAttachment],
  );
  const uppy = useMemo(
    () =>
      attachmentUppy(attachmentData => {
        logger.debug('Upload response: ', attachmentData);

        // Reload attachments
        refetch().then(({data: results}) => {
          // Select uploaded image
          const uploaded = itemFromResults(attachmentData.id, results);
          if (uploaded) {
            handleSelect({item: uploaded});
          }
        });
        // Close Uppy
        setOpenUppy(false);
      }),
    [handleSelect, refetch],
  );
  const onSaveUnsplash = useCallback(
    attachmentData => {
      logger.debug('Upload unsplash response: ', attachmentData);

      // Reload attachments
      refetch().then(({data: results}) => {
        // Select uploaded image
        const uploaded = itemFromResults(attachmentData.id, results);
        if (uploaded) {
          handleSelect({item: uploaded});
        }
      });
      // Close Unsplash
      setOpenUnsplash(false);
    },
    [handleSelect, refetch],
  );
  const onSaveVideo = useCallback(
    attachmentData => {
      logger.debug('Upload video response: ', attachmentData);

      // Reload attachments
      refetch().then(({data: results}) => {
        // Select uploaded image
        const uploaded = itemFromResults(attachmentData.id, results);
        if (uploaded) {
          handleSelect({item: uploaded});
        }
      });
      // Close Video Picker
      setOpenVideoPicker(false);
    },
    [handleSelect, refetch],
  );

  useEffect(
    () => () => {
      setOpenUppy(false);
      setOpenUnsplash(false);
      setOpenVideoPicker(false);
    },
    [],
  );

  if (loading) {
    return <DatagridLoading />;
  }

  const errors = [loadError, deleteError].filter(Boolean);
  if (errors.length) {
    return <ErrorHandler error={errors} title="Attachment Op Error" />;
  }

  const {
    findAttachments: {nodes: mediaItems},
  } = data;

  return (
    <>
      <MediaModal
        open={open}
        onSave={() => onSave({selectedItems})}
        onClose={() => onClose && onClose({selectedItems})}
        mediaItems={mediaItems}
        getMediaAsset={(item, i) => item.previewImageUrl}
        isLoadingItems={loading}
        uploadSources={UPLOAD_SOURCES}
        onUploadFromSource={source => {
          switch (source) {
            case UPLOAD_SOURCE_UPLOADS:
              setOpenUppy(true);
              break;
            case UPLOAD_SOURCE_UNSPLASH:
              setOpenUnsplash(true);
              break;
            case UPLOAD_SOURCE_VIDEO:
              setOpenVideoPicker(true);
              break;
            default:
              logger.warn('Unsupported media source:', source);
              setOpenUppy(true);
          }
        }}
        isItemSelected={({item}) => !!selectedItems[item.id]}
        onSelectItem={handleToggleSelect}
        onDeleteItem={deleteSpec => setPendingDelete(deleteSpec)}
        isDeleting={isDeleting}
        onEditItem={NOT_YET_IMPLEMENTED}
      />
      {openUppy && (
        // New Uppy model with new context
        <DashboardModal
          uppy={uppy}
          open
          onRequestClose={() => setOpenUppy(false)}
          target={document.body}
          showProgressDetails
          closeModalOnClickOutside
          metaFields={[
            {
              id: 'title',
              name: 'Title',
              placeholder: 'Important. Drives SEO',
            },
            {
              id: 'caption',
              name: 'Caption',
              placeholder: 'Caption text under image. Short description here',
            },
            {
              id: 'altText',
              name: 'Alt Text',
              placeholder: 'Text shown when image fails to display',
            },
            {
              id: 'description',
              name: 'Description',
              placeholder: 'Detailed description of what the image is about',
            },
          ]}
          note="Images only, up to 10 MB"
        />
      )}
      {openUnsplash && (
        // New dialog so no context is maintained
        <UnsplashPicker
          open
          commitOnSelect
          onSave={onSaveUnsplash}
          onClose={() => {
            setOpenUnsplash(false);
          }}
          value={null}
        />
      )}
      {openVideoPicker && (
        // New dialog so no context is maintained
        <EmbedVideoModal
          open
          commitOnSelect
          onSave={onSaveVideo}
          onCancel={() => {
            setOpenVideoPicker(false);
          }}
          value={null}
        />
      )}
      {!!pendingDelete && (
        // New confirm dialog so no context is maintained
        <Confirm
          open
          content={`Are you sure you want to delete "${pendingDelete.item.title}?"`}
          confirmButton="Yes. Delete Image"
          onConfirm={() => handleDeleteItem(pendingDelete)}
          onCancel={() => {
            setPendingDelete(null);
          }}
        />
      )}
    </>
  );
};

EditorMediaPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  supportsMultiSelect: PropTypes.bool,
  commitOnSelect: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

EditorMediaPicker.defaultProps = {
  onClose: undefined,
  supportsMultiSelect: false,
  commitOnSelect: false,
  value: null,
};

export default EditorMediaPicker;
