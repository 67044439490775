import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Button, Icon} from 'semantic-ui-react';
import {colors, styles} from '../../../../../../theme';
import MediaTypeIndicator from '../../../../shared-ui/MediaTypeIndicator';

const ImageWrapper = styled.div`
  position: relative;
  flex-basis: 192px;
  width: 192px;
  height: 128px;
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid ${colors.textFieldBorder};
  border-radius: 3px;
  overflow: hidden;
  ${styles.effects.checkerboard};
  ${styles.shadows.inset};
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Image = props => <StyledImage role="presentation" {...props} />;

const MultiImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FileLink = styled.a`
  margin-bottom: 20px;
  font-weight: normal;
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;

const FileLinks = styled.div`
  margin-bottom: 12px;
`;

const FileLinkList = styled.ul`
  list-style-type: none;
`;

const MAX_DISPLAY_LENGTH = 50;

const renderFileLink = value => {
  const size = MAX_DISPLAY_LENGTH;
  if (!value || value.length <= size) {
    return value;
  }
  const text = `${value.substring(0, size / 2)}\u2026${value.substring(
    value.length - size / 2 + 1,
    value.length,
  )}`;
  return (
    <FileLink href={value} rel="noopener" target="_blank">
      {text}
    </FileLink>
  );
};

const FileControl = ({
  id,
  className,
  field,
  onOpenMediaLibrary,
  onClearMedia,
  forMedia,
  clearable,
  value,
}) => {
  const handleChange = useCallback(
    ev => {
      ev.preventDefault();

      // const mediaLibraryFieldOptions = field.mediaLibrary || {};
      return onOpenMediaLibrary({id, forMedia, field, value});
    },
    [id, forMedia, field, value, onOpenMediaLibrary],
  );

  const handleRemove = useCallback(
    ev => {
      ev.preventDefault();
      return onClearMedia({id, forMedia, field, value});
    },
    [id, forMedia, field, value, onClearMedia],
  );

  const renderFileLinks = useCallback(() => {
    if (Array.isArray(value)) {
      return (
        <FileLinks>
          <FileLinkList>
            {value.map(val => (
              <li key={val}>{renderFileLink(val)}</li>
            ))}
          </FileLinkList>
        </FileLinks>
      );
    }
    return <FileLinks>{renderFileLink(value)}</FileLinks>;
  }, [value]);

  const renderImages = useCallback(() => {
    if (Array.isArray(value)) {
      return (
        <MultiImageWrapper>
          {value.map(val => (
            <ImageWrapper key={val}>
              <Image src={val.previewImageUrl || ''} />
            </ImageWrapper>
          ))}
        </MultiImageWrapper>
      );
    }

    return (
      <ImageWrapper>
        <MediaTypeIndicator mediaType={value.attachmentType} />
        <Image src={value.previewImageUrl || ''} />
      </ImageWrapper>
    );
  }, [value]);

  const renderSelection = useCallback(
    () => (
      <div>
        {forMedia ? renderImages() : null}
        <div>
          {forMedia ? null : renderFileLinks()}
          <Button onClick={handleChange}>
            {forMedia ? 'Choose different media' : 'Choose different file'}
          </Button>
          {clearable && (
            <Button onClick={handleRemove}>
              <Icon name="close" />
              Remove
            </Button>
          )}
        </div>
      </div>
    ),
    [
      handleChange,
      handleRemove,
      forMedia,
      clearable,
      renderFileLinks,
      renderImages,
    ],
  );

  const renderNoSelection = useCallback(
    () => (
      <Button onClick={handleChange}>
        {forMedia ? 'Choose Media' : 'Choose file'}
      </Button>
    ),
    [handleChange, forMedia],
  );

  return (
    <div className={className}>
      <span>{value ? renderSelection() : renderNoSelection()}</span>
    </div>
  );
};

FileControl.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  onOpenMediaLibrary: PropTypes.func.isRequired,
  onClearMedia: PropTypes.func.isRequired,
  forMedia: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};
//
FileControl.defaultProps = {
  className: undefined,
  value: undefined,
  forMedia: false,
  clearable: true,
};

export default FileControl;
