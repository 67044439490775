import produce from 'immer';
import logger from '../../../logger';

const allowedEvents = [
  'prePublish',
  'postPublish',
  'preUnpublish',
  'postUnpublish',
];
const eventHandlers = {};
allowedEvents.forEach(e => {
  eventHandlers[e] = [];
});

/**
 * Global Registry Object
 */
const registry = {
  templates: {},
  previewStyles: [],
  widgets: {},
  valueSerializers: {},
  dataFetchers: {},
  selectOptionConverters: {},
  mediaLibraries: [],
  locales: {},
  eventHandlers,
};

/**
 * Preview Styles
 *
 * Valid options:
 *  - raw {boolean} if `true`, `style` value is expected to be a CSS string
 */
export function registerPreviewStyle(style, opts) {
  registry.previewStyles.push({...opts, value: style});
}
export function getPreviewStyles() {
  return registry.previewStyles;
}

/**
 * Preview Templates
 */
export function registerPreviewTemplate(name, component) {
  registry.templates[name] = component;
}
export function getPreviewTemplate(name) {
  return registry.templates[name];
}

/**
 * Editor Widgets
 */
export function registerWidget(name, control, preview) {
  if (Array.isArray(name)) {
    name.forEach(widget => {
      if (typeof widget !== 'object') {
        logger.error(`Cannot register widget: ${widget}`);
      } else {
        registerWidget(widget);
      }
    });
  } else if (typeof name === 'string') {
    // A registered widget control can be reused by a new widget, allowing
    // multiple copies with different previews.
    const newControl =
      typeof control === 'string' ? registry.widgets[control].control : control;
    registry.widgets[name] = {control: newControl, preview};
  } else if (typeof name === 'object') {
    const {
      name: widgetName,
      controlComponent,
      previewComponent,
      allowMapValue,
      globalStyles,
      ...options
    } = name;
    // if (registry.widgets[widgetName]) {
    //   logger.warn(oneLine`
    //     Multiple widgets registered with name "${widgetName}". Only the last widget registered with
    //     this name will be used.
    //   `);
    // }
    if (!controlComponent) {
      throw Error(
        `Widget "${widgetName}" registered without \`controlComponent\`.`,
      );
    }
    registry.widgets[widgetName] = {
      control: controlComponent,
      preview: previewComponent,
      globalStyles,
      allowMapValue,
      ...options,
    };
  } else {
    logger.error('`registerWidget` failed, called with incorrect arguments.');
  }
}

export function getWidget(name) {
  const widget = registry.widgets[name];
  if (!widget) {
    const nameLowerCase = name.toLowerCase();
    const hasLowerCase = !!registry.widgets[nameLowerCase];
    const message = hasLowerCase
      ? `Could not find widget '${name}'. Did you mean '${nameLowerCase}'?`
      : `Could not find widget '${name}'. Please make sure the widget name is configured correctly or register it via 'registerWidget'.`;
    throw new Error(message);
  }
  return widget;
}

export function getWidgets() {
  return produce(Object.entries(registry.widgets), draft =>
    draft.map(([key, value]) => ({name: key, ...value})),
  );
}

export function resolveWidget(name) {
  return getWidget(name || 'string') || getWidget('unknown');
}

/**
 * Widget Serializers
 */
export function registerValueSerializer(name, serializer) {
  registry.valueSerializers[name] = serializer;
}
export function getValueSerializer(name) {
  return registry.valueSerializers[name];
}

/**
 * Data fetchers
 */
export function registerDataFetcher(fetcherName, fetcher) {
  registry.dataFetchers[fetcherName] = fetcher;
}
export function getDataFetcher(fetcherName) {
  return registry.dataFetchers[fetcherName];
}

/**
 * Select option converters
 */
export function registerSelectOptionConverter(converterName, converter) {
  registry.selectOptionConverters[converterName] = converter;
}
export function getSelectOptionConverter(converterName) {
  return registry.selectOptionConverters[converterName];
}

/**
 * Media Libraries
 */
export function registerMediaLibrary(mediaLibrary, options) {
  if (registry.mediaLibraries.find(ml => mediaLibrary.name === ml.name)) {
    throw new Error(
      `A media library named ${mediaLibrary.name} has already been registered.`,
    );
  }
  registry.mediaLibraries.push({...mediaLibrary, options});
}

export function getMediaLibrary(name) {
  return registry.mediaLibraries.find(ml => ml.name === name);
}

function validateEventName(name) {
  if (!allowedEvents.includes(name)) {
    throw new Error(`Invalid event name '${name}'`);
  }
}

export function getEventListeners(name) {
  validateEventName(name);
  return [...registry.eventHandlers[name]];
}

export function registerEventListener({name, handler}, options = {}) {
  validateEventName(name);
  registry.eventHandlers[name].push({handler, options});
}

export async function invokeEvent({name, data}) {
  validateEventName(name);
  const handlers = registry.eventHandlers[name];

  // eslint-disable-next-line no-restricted-syntax
  for (const {handler, options} of handlers) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await handler(data, options);
    } catch (e) {
      logger.warn(
        `Failed running handler for event ${name} with message: ${e.message}`,
      );
    }
  }
}

export function removeEventListener({name, handler}) {
  validateEventName(name);
  if (handler) {
    registry.eventHandlers[name] = registry.eventHandlers[name].filter(
      item => item.handler !== handler,
    );
  } else {
    registry.eventHandlers[name] = [];
  }
}

/**
 * Locales
 */
export function registerLocale(locale, phrases) {
  if (!locale || !phrases) {
    logger.error(
      "Locale parameters invalid. example: CMS.registerLocale('locale', phrases)",
    );
  } else {
    registry.locales[locale] = phrases;
  }
}

export function getLocale(locale) {
  return registry.locales[locale];
}

export default {
  registerPreviewStyle,
  getPreviewStyles,
  registerPreviewTemplate,
  getPreviewTemplate,
  registerWidget,
  getWidget,
  getWidgets,
  resolveWidget,
  registerValueSerializer,
  getValueSerializer,
  registerSelectOptionConverter,
  getSelectOptionConverter,
  registerMediaLibrary,
  getMediaLibrary,
  registerLocale,
  getLocale,
  registerEventListener,
  removeEventListener,
  getEventListeners,
  invokeEvent,
};
