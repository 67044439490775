import React from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import {SemanticToastContainer} from 'react-semantic-toasts';
import {colors} from '../../theme';
import AdminContainer from './layouts/AdminContainer';
import NavMenu from './layouts/NavMenu';
import AdminHeader from './layouts/AdminHeader';
import AdminContent from './layouts/AdminContent';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import CMS from './cms';

TopBarProgress.config({
  barColors: {
    0: colors.brandPrimary,
    '1.0': colors.brandPrimary,
  },
  shadowBlur: 0,
  barThickness: 2,
});
CMS.init();

function AdminApp({children, location}) {
  return (
    <AdminContainer location={location}>
      <AdminHeader />
      <AdminContent sidebar={NavMenu}>{children}</AdminContent>
      <SemanticToastContainer position="top-center" />
    </AdminContainer>
  );
}

export default AdminApp;
