import React, {useCallback, useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import {filter} from 'graphql-anywhere';
import EditMediaInfoModal from '../editor/EditMediaInfoModal';
import MediaModal from '../media/MediaModal';
import AttachmentGql from '../../../../gql/frontend/AttachmentGql';
import UnsplashPhotoGql from '../../../../gql/frontend/UnsplashPhotoGql';
import ErrorHandler from '../../ErrorHandler';
import logger from '../../../../logger';
import ToolbarDropdownItem from '../toolbar/ToolbarDropdownItem';

const DEFAULT_SEARCH_TERM = 'Real Estate';
const DEFAULT_ORIENTATION = 'LANDSCAPE';
const ORIENTATION_OPTIONS = {
  LANDSCAPE: 'Landscape',
  PORTRAIT: 'Portrait',
  SQUARISH: 'Square',
};

const OrientationSelect = ({orientation, onSelectOrientation}) => (
  <ToolbarDropdownItem
    value={orientation}
    options={ORIENTATION_OPTIONS}
    onSelectOption={data => {
      logger.debug('Orientation: ', data.value);
      if (onSelectOrientation) onSelectOrientation(data.value);
    }}
  />
);

const UnsplashPicker = ({open, onSave, onClose, value}) => {
  const [searchTerm, setSearchTerm] = useState(DEFAULT_SEARCH_TERM);
  const [orientation, setOrientation] = useState(DEFAULT_ORIENTATION);
  const [selectedItem, setSelectedItem] = useState(value || {});
  const [editMediaInfo, setEditMediaInfo] = useState(null);
  const [
    searchUnsplash,
    {loading, data: loadedData, error: loadingError},
  ] = useLazyQuery(UnsplashPhotoGql.queries.search);
  const [createAttachment, {error: createError}] = useMutation(
    AttachmentGql.queries.create,
  );

  const handleOnSelect = useCallback(
    ({item}) => {
      setSelectedItem(item);
      setEditMediaInfo({...item, previewImageUrl: item.urls?.regular});
    },
    [setSelectedItem],
  );

  const handleSaveImage = useCallback(
    ({values}) => {
      const external = {storage: 'UNSPLASH', ...selectedItem};
      const data = filter(AttachmentGql.fragments.createEntry, {
        ...values,
        external,
      });
      createAttachment({
        variables: {data},
      }).then(({data: {createAttachment: attachmentData}}) =>
        onSave(attachmentData),
      );
    },
    [createAttachment, onSave, selectedItem],
  );

  useEffect(() => {
    searchUnsplash({variables: {searchTerm, orientation}});
  }, [orientation, searchTerm, searchUnsplash]);

  const errors = [loadingError, createError].filter(Boolean);
  if (errors && errors.length) {
    return <ErrorHandler error={errors} asModal />;
  }

  const mediaItems = loadedData?.searchUnsplashPhotos?.nodes || [];
  const toolbarChildren = (
    <OrientationSelect
      orientation={orientation}
      onSelectOrientation={setOrientation}
    />
  );
  return (
    <>
      <MediaModal
        open={open}
        onClose={() => onClose && onClose({selectedItem})}
        onSearch={term => setSearchTerm(term)}
        searchValue={searchTerm}
        searchPlaceholder="Search Unsplash..."
        isSearching={loading}
        mediaItems={mediaItems}
        getMediaAsset={(item, i) => item.urls?.thumb}
        isLoadingItems={loading}
        isItemSelected={({item}) => selectedItem.id === item.id}
        onSelectItem={handleOnSelect}
        isItemEditable={() => false}
        isItemDeletable={() => false}
        toolbarChildren={toolbarChildren}
      />
      {editMediaInfo && (
        <EditMediaInfoModal
          {...editMediaInfo}
          open
          onSave={handleSaveImage}
          onCancel={() => setEditMediaInfo(null)}
        />
      )}
    </>
  );
};

UnsplashPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  value: PropTypes.object,
};

UnsplashPicker.defaultProps = {
  onClose: undefined,
  value: null,
};

export default UnsplashPicker;
