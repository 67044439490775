import React from 'react';
import PropTypes from 'prop-types';
import {Card, Dimmer, Loader, Modal} from 'semantic-ui-react';
import styled from 'styled-components';
import MediaToolbar from './MediaToolbar';
import MediaItemImage from './MediaItemImage';

const DEFAULT_ITEMS_PER_ROW = 4;

const EmptyContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyMessage = ({children}) => (
  <EmptyContainer>
    <h2>{children}</h2>
  </EmptyContainer>
);

const Loading = ({message}) => (
  <EmptyContainer>
    <Dimmer>
      <Loader content={message} />
    </Dimmer>
  </EmptyContainer>
);

const MediaModal = ({
  open,
  onClose,
  onSave,
  onSearch,
  searchValue,
  searchPlaceholder,
  isSearching,
  mediaItems,
  getMediaAsset,
  itemsPerRow,
  isLoadingItems,
  uploadSources,
  onUploadFromSource,
  isUploading,
  disableUpload,
  canSearch,
  isItemSelected,
  isItemSelectable,
  onSelectItem,
  isSelecting,
  isItemDeletable,
  onDeleteItem,
  isDeleting,
  isItemEditable,
  onEditItem,
  isEditing,
  toolbarChildren,
  ...rest
}) => {
  let content;
  if (isLoadingItems) {
    content = <Loading message="Loading" />;
  } else if (!mediaItems.length) {
    content = <EmptyMessage>No assets found.</EmptyMessage>;
  } else {
    content = (
      <Card.Group itemsPerRow={itemsPerRow || DEFAULT_ITEMS_PER_ROW}>
        {mediaItems.map((item, index) => (
          <MediaItemImage
            key={item.id}
            item={item}
            index={index}
            previewUrl={getMediaAsset(item, index)}
            isSelected={isItemSelected({item, index})}
            selectable={
              isItemSelectable == null || isItemSelectable({item, index})
            }
            onSelectItem={onSelectItem}
            isSelecting={isSelecting}
            deletable={
              isItemDeletable == null || isItemDeletable({item, index})
            }
            onDeleteItem={deleteParams => {
              if (onDeleteItem) onDeleteItem(deleteParams);
            }}
            isDeleting={isDeleting}
            editable={isItemEditable == null || isItemEditable({item, index})}
            onEditItem={editParams => {
              if (onEditItem) onEditItem(editParams);
            }}
            isEditing={isEditing}
          />
        ))}
      </Card.Group>
    );
  }

  return (
    <Modal open={open} onClose={onClose} {...rest} closeIcon>
      <Modal.Header>
        <MediaToolbar
          onSave={onSave}
          onSearch={onSearch}
          searchValue={searchValue}
          searchPlaceholder={searchPlaceholder}
          isSearching={isSearching}
          uploadSources={uploadSources}
          onUploadFromSource={onUploadFromSource}
          isUploading={isUploading}
          disableUpload={disableUpload}>
          {toolbarChildren}
        </MediaToolbar>
      </Modal.Header>
      <Modal.Content>{content}</Modal.Content>
    </Modal>
  );
};

MediaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  isSearching: PropTypes.bool,
  mediaItems: PropTypes.array,
  getMediaAsset: PropTypes.func.isRequired,
  itemsPerRow: PropTypes.number,
  isLoadingItems: PropTypes.bool,
  onUploadFromSource: PropTypes.func,
  uploadSources: PropTypes.arrayOf(PropTypes.string),
  isUploading: PropTypes.bool,
  disableUpload: PropTypes.bool,
  canSearch: PropTypes.bool,
  isItemSelected: PropTypes.func.isRequired,
  isItemSelectable: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
  isSelecting: PropTypes.bool,
  isItemDeletable: PropTypes.func,
  onDeleteItem: PropTypes.func,
  isDeleting: PropTypes.bool,
  isItemEditable: PropTypes.func,
  onEditItem: PropTypes.func,
  isEditing: PropTypes.bool,
  toolbarChildren: PropTypes.node,
};

MediaModal.defaultProps = {
  onSave: undefined,
  onSearch: undefined,
  searchValue: undefined,
  searchPlaceholder: undefined,
  isSearching: false,
  mediaItems: [],
  itemsPerRow: DEFAULT_ITEMS_PER_ROW,
  isLoadingItems: false,
  canSearch: false,
  uploadSources: undefined,
  onUploadFromSource: undefined,
  onDeleteItem: undefined,
  onEditItem: undefined,
  isUploading: false,
  isSelecting: false,
  isDeleting: false,
  isEditing: false,
  disableUpload: false,
  isItemSelectable: undefined,
  isItemDeletable: undefined,
  isItemEditable: undefined,
  toolbarChildren: undefined,
};

export default MediaModal;
