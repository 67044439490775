import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Icon, Image} from 'semantic-ui-react';
import styled from 'styled-components';
import {colors} from '../../../../theme';
import MediaTypeIndicator from '../../shared-ui/MediaTypeIndicator';

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const SelectedIndicatorContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.25rem;
  color: white;
  background-color: ${colors.brandPrimary};
`;
const SelectedIndicator = () => (
  <SelectedIndicatorContainer>
    <Icon name="checkmark" size="large" />
  </SelectedIndicatorContainer>
);

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-height: 200px;
  min-height: 200px;
  max-width: 200px;
  object-fit: cover;
`;

const MediaItemImage = ({
  item,
  index,
  previewUrl,
  isSelected,
  selectable,
  onSelectItem,
  isSelecting,
  deletable,
  onDeleteItem,
  isDeleting,
  editable,
  onEditItem,
  isEditing,
}) => {
  const [showControls, setShowControls] = useState(false);
  const handleOnClick = useCallback(
    ev => {
      ev.stopPropagation();
      if (selectable && onSelectItem) {
        onSelectItem({item, index});
      }
    },
    [selectable, onSelectItem, item, index],
  );
  const handleOnDeleteClick = useCallback(
    ev => {
      ev.stopPropagation();
      if (deletable && onDeleteItem) {
        onDeleteItem({item, index});
      }
    },
    [deletable, onDeleteItem, item, index],
  );
  const handleOnEditClick = useCallback(
    ev => {
      ev.stopPropagation();
      if (editable && onEditItem) {
        onEditItem({item, index});
      }
    },
    [editable, onEditItem, item, index],
  );

  const stateProps = isSelected ? {raised: true, color: 'teal'} : {};
  const activityInProgress = isSelecting || isDeleting || isEditing;
  return (
    <Card
      id={`image-item-${item.id}`}
      {...stateProps}
      onClick={handleOnClick}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}>
      <StyledImage src={previewUrl} />
      <MediaTypeIndicator mediaType={item.attachmentType} />
      {isSelected && <SelectedIndicator />}
      {showControls && (
        <ControlsContainer>
          {deletable && (
            <Button
              color="red"
              icon="trash"
              disabled={activityInProgress}
              onClick={handleOnDeleteClick}
            />
          )}
          {editable && (
            <Button
              color="teal"
              icon="edit"
              disabled={activityInProgress}
              onClick={handleOnEditClick}
            />
          )}
        </ControlsContainer>
      )}
    </Card>
  );
};

MediaItemImage.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  previewUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  selectable: PropTypes.bool.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  isSelecting: PropTypes.bool,
  deletable: PropTypes.bool,
  onDeleteItem: PropTypes.func,
  isDeleting: PropTypes.bool,
  editable: PropTypes.bool,
  onEditItem: PropTypes.func,
  isEditing: PropTypes.bool,
};

MediaItemImage.defaultProps = {
  onDeleteItem: undefined,
  onEditItem: undefined,
  isSelected: false,
  isSelecting: false,
  deletable: false,
  isDeleting: false,
  editable: false,
  isEditing: false,
};

export default MediaItemImage;
