const pageDefaults = {
  home: {
    title: 'Your Austin Living Guide',
    intro:
      "<p>Having lived and worked as a Realtor in Austin Texas, several themes continue to pop up. People search for homes based on topics/stage of life. So I figured, why don't we create a quick and easy way to research home availability based on things peoples care about. Sure, a general search works for window shopping , but really what matters are homes that work with our day to day schedule. What homes are available in my neighborhood, by my job, for my kids?</p><p>Here are a collection of resources that can help you navigate life and living in Austin Texas!</p>",
  },
  about: {
    title: 'About Me',
    content:
      '<p class="ql-align-center">👋&nbsp;Hi I\'m&nbsp;<strong>Angie</strong>. I’m a native of Louisiana and have had the opportunity to travel around the world as a military “brat”. This experience shaped my view of the world and has allowed me to meet people from all over the world.</p><p class="ql-align-center">I graduated high school in Arizona, and then attended Midwestern State University in Wichita Falls, TX. This started my lifelong love of the great state of Texas.</p><p class="ql-align-center">After college I utilized my Psychology degree and went into the mental health field. Later I became a middle school teacher in Round Rock ISD for 8 years.</p><p class="ql-align-center">Following my career in education I became a business owner. Having tasted entrepreneurship with owning a Kickboxing fitness studio and a home organizing business, Real Estate naturally became a passion of mine.</p><p class="ql-align-center">I enjoy helping people realize their dreams in a home. With my vast experience in different fields and expertise in helping others I am able to truly understand my clients’ needs and give personalized high quality service. Whatever you real estate needs are, allow me to assist you in achieving them.</p>',
  },
};

export default pageDefaults;
