import controlComponent from './StringControl';
import previewComponent from './StringPreview';

const Widget = (opts = {}) => ({
  name: 'string',
  controlComponent,
  previewComponent,
  ...opts,
});

export const CmsWidgetString = {
  Widget,
  controlComponent,
  previewComponent,
};
export default CmsWidgetString;
