import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Input, Menu} from 'semantic-ui-react';
import classNames from 'classnames';

const ToolbarSearchItem = ({
  className,
  value,
  placeholder,
  icon,
  onSearch,
  isSearching,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <Menu.Item className={classNames(className)}>
      <form
        onSubmit={ev => {
          ev.stopPropagation();
          ev.preventDefault();
          if (onSearch) onSearch(inputValue);
        }}>
        <Input
          size="large"
          icon={icon}
          value={inputValue}
          placeholder={placeholder}
          loading={isSearching}
          onChange={(event, data) => setInputValue(data.value)}
          {...rest}
        />
      </form>
    </Menu.Item>
  );
};

ToolbarSearchItem.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  onSearch: PropTypes.func,
  isSearching: PropTypes.bool,
  value: PropTypes.string,
};

ToolbarSearchItem.defaultProps = {
  className: undefined,
  value: undefined,
  placeholder: 'Search...',
  icon: 'search',
  onSearch: undefined,
  isSearching: false,
};

export default ToolbarSearchItem;
