import PropTypes from 'prop-types';
import {Dropdown, Menu} from 'semantic-ui-react';
import React, {useMemo, useState} from 'react';

const ToolbarDropdownItem = ({
  label,
  icon,
  dropdownHeader,
  options,
  onSelectOption,
  ...rest
}) => {
  // Reset the dropdown selection on every select
  const [key, setKey] = useState(new Date().toISOString());
  const dropdownOptions = useMemo(() => {
    const valueLabels = Array.isArray(options)
      ? options.map(option => [option, option])
      : Object.entries(options);
    return valueLabels.map(([value, lbl]) => ({
      key: value,
      value,
      text: lbl,
      content: lbl,
    }));
  }, [options]);

  const iconProps = icon ? {labeled: true, className: 'icon', icon} : {};

  return (
    <Menu.Item className="datagridmenu__menu_item:button">
      <Dropdown
        key={key}
        button
        size="medium"
        header={dropdownHeader}
        options={dropdownOptions}
        text={label}
        onChange={(ev, data) => {
          setKey(new Date().toISOString());
          onSelectOption(data);
        }}
        {...iconProps}
        {...rest}
      />
    </Menu.Item>
  );
};

ToolbarDropdownItem.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  dropdownHeader: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ToolbarDropdownItem.defaultProps = {
  dropdownHeader: undefined,
  label: undefined,
  icon: undefined,
};

export default ToolbarDropdownItem;
