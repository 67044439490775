import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '../toolbar/Toolbar';
import ToolbarDropdownItem from '../toolbar/ToolbarDropdownItem';
import logger from '../../../../logger';
import ToolbarActionItem from '../toolbar/ToolbarActionItem';
import ToolbarSearchItem from '../toolbar/ToolbarSearchItem';
import ToolbarGroup from '../toolbar/ToolbarGroup';

const uploadSourcesToOptions = uploadSources => {
  const sourceToLabel = source => {
    switch (source) {
      case 'uploads':
        return 'My Files';
      case 'unsplash':
        return 'Unsplash';
      case 'video':
        return 'Video';
      default:
        logger.error('Unsupported upload source: ', source);
        return '';
    }
  };

  const options = {};
  uploadSources.forEach(source => {
    options[source] = sourceToLabel(source);
  });

  return options;
};

const UploadSelect = ({
  isUploading,
  isDisabled,
  uploadSources,
  onUploadFromSource,
}) => (
  <ToolbarDropdownItem
    dropdownHeader="Upload Media From"
    options={uploadSourcesToOptions(uploadSources)}
    onSelectOption={data => {
      logger.debug('Upload source: ', data.value);
      if (onUploadFromSource) onUploadFromSource(data.value);
    }}
    disabled={isDisabled}
    label="Add New Media"
    loading={isUploading}
  />
);

const MediaToolbar = ({
  onSave,
  onSearch,
  searchValue,
  searchPlaceholder,
  isSearching,
  onUploadFromSource,
  uploadSources,
  isUploading,
  disableUpload,
  children,
}) => (
  <Toolbar>
    {onSave && <ToolbarActionItem label="Save" color="teal" onClick={onSave} />}
    {Array.isArray(uploadSources) && uploadSources.length && (
      <UploadSelect
        isUploading={isUploading}
        isDisabled={isUploading || disableUpload}
        uploadSources={uploadSources}
        onUploadFromSource={onUploadFromSource}
      />
    )}
    {onSearch && (
      <ToolbarGroup>
        <ToolbarSearchItem
          value={searchValue}
          placeholder={searchPlaceholder}
          onSearch={onSearch}
          isSearching={isSearching}
        />
      </ToolbarGroup>
    )}
    {children}
  </Toolbar>
);

MediaToolbar.propTypes = {
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  isSearching: PropTypes.bool,
  onUploadFromSource: PropTypes.func,
  uploadSources: PropTypes.arrayOf(PropTypes.string),
  isUploading: PropTypes.bool,
  disableUpload: PropTypes.bool,
};

MediaToolbar.defaultProps = {
  onSave: undefined,
  onSearch: undefined,
  searchValue: undefined,
  searchPlaceholder: undefined,
  isSearching: false,
  uploadSources: undefined,
  onUploadFromSource: undefined,
  isUploading: false,
  disableUpload: false,
};

export default MediaToolbar;
