import React from 'react';
import {Menu} from 'semantic-ui-react';
import {Link} from 'gatsby';

// https://reach.tech/router/api/Link
const activeClassName = 'item active';
const isActive = ({isCurrent}) =>
  isCurrent ? {className: activeClassName} : null;
const isPartiallyActive = ({isPartiallyCurrent}) =>
  isPartiallyCurrent ? {className: activeClassName} : null;

const NavLink = ({isExact, ...rest}) => (
  <Link getProps={isExact ? isActive : isPartiallyActive} {...rest} />
);

const NavMenu = () => (
  <Menu className="admin__nav_menu" vertical secondary>
    <Menu.Item as={NavLink} to="/admin" isExact>
      Dashboard
    </Menu.Item>
    <Menu.Item as={NavLink} to="/admin/articles">
      Articles
    </Menu.Item>
    <Menu.Item as={NavLink} to="/admin/article-categories">
      Article Categories
    </Menu.Item>
    <Menu.Item as={NavLink} to="/admin/pages">
      Site Pages
    </Menu.Item>
    <Menu.Item as={NavLink} to="/admin/web-messages">
      Web Messages
    </Menu.Item>
    <Menu.Item as={NavLink} to="/admin/settings">
      Site Settings
    </Menu.Item>
  </Menu>
);

export default NavMenu;
