import extensions from './extensions';
import registry from './registry';

const init = () => {
  extensions.bootstrap();
};

const CMS = {
  ...registry,
  init,
};
export default CMS;
