import React from 'react';
import {Header} from 'semantic-ui-react';
import HomeHero from './HomeHero';
import SEO from '../SEO/SEO';
import ArticlesListTemplate from '../ArticlesList/ArticlesListTemplate';
import Page from '../../site/layouts/Page';
import pageDefaults from '../../../pageDefaults';

const RecentBlogArticles = ({articles}) => {
  if (!(articles && articles.length)) return null;
  return (
    <>
      <Header as="h1" className="huge-tm big-bm">
        Recent Articles
      </Header>
      <ArticlesListTemplate articles={articles} />
    </>
  );
};

const HomePage = ({entry, articles}) => {
  const {home: homePageDefaults} = pageDefaults;
  const title = entry?.title?.length ? entry.title : homePageDefaults.title;
  const intro = entry?.intro?.length ? entry.intro : homePageDefaults.intro;
  const sidebar = entry?.sidebar?.length ? entry.sidebar : 'standard';
  return (
    <Page hero={<HomeHero />} sidebar={!!sidebar}>
      <SEO title={title} />
      <Header as="h1">{title}</Header>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{__html: intro}} />
      <RecentBlogArticles articles={articles} />
    </Page>
  );
};

export default HomePage;
