import React from 'react';
import PropTypes from 'prop-types';
import WidgetPreviewContainer from '../WidgetPreviewContainer';

const TextPreview = ({value, field}) => (
  <WidgetPreviewContainer label={field.label}>{value}</WidgetPreviewContainer>
);

TextPreview.propTypes = {
  value: PropTypes.node,
  field: PropTypes.object.isRequired,
};
TextPreview.defaultProps = {
  value: undefined,
};

export default TextPreview;
