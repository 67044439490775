/* eslint-disable class-methods-use-this */
const {Plugin} = require('@uppy/core');
const settle = require('@uppy/utils/lib/settle');
const EventTracker = require('@uppy/utils/lib/EventTracker');
const {getApolloClient} = require('../apollo/client');

const LOG_TAG = '[AhpGqlUpload]';

/**
 * Set `data.type` in the blob to `file.meta.type`,
 * because we might have detected a more accurate file type in Uppy
 * https://stackoverflow.com/a/50875615
 *
 * @param {object} file File object with `data`, `size` and `meta` properties
 * @returns {object} blob updated with the new `type` set from `file.meta.type`
 */
function blobFromFile(file) {
  return file.data.slice(0, file.data.size, file.meta.type);
}

/**
 * Modeled after Uppy XHRUpload plugin:
 * https://github.com/transloadit/uppy/blob/master/packages/@uppy/xhr-upload/src/index.js
 *
 * See plugin guide here:
 * https://uppy.io/docs/writing-plugins/
 */
class AhpGqlUpload extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = 'uploader';
    this.id = opts.id || 'AhpGqlUpload';
    this.title = 'AhpGqlUpload';
    this.uploaderEvents = {};

    const defaultOpts = {
      fieldName: 'files',
      metaFields: null,
    };

    this.opts = {...defaultOpts, ...opts};
    this.handleUpload = this.handleUpload.bind(this);
  }

  install() {
    this.uppy.addUploader(this.handleUpload);
  }

  uninstall() {
    this.uppy.removeUploader(this.handleUpload);
  }

  handleUpload(fileIds) {
    if (!fileIds?.length) {
      this.log('No files to upload!');
      return Promise.resolve();
    }

    this.log('Uploading...');
    const files = fileIds.map(fileId => this.uppy.getFile(fileId));
    return this.uploadFiles(files).then(() => null);
  }

  uploadFiles(files) {
    const promises = files.map((file, i) => {
      const current = parseInt(i, 10) + 1;
      const total = files.length;

      if (file.error) {
        return Promise.reject(new Error(file.error));
      }

      if (file.isRemote) {
        return this.uploadRemote(file, current, total);
      }

      return this.upload(file, current, total);
    });

    return settle(promises);
  }

  async upload(file, current, total) {
    const opts = this.getFileOptions(file);

    this.log(`uploading ${current} of ${total}`);
    this.uppy.emit('upload-started', file);
    this.uploaderEvents[file.id] = new EventTracker(this.uppy);

    const data = this.fileMetaVariables(file.meta, opts);
    const upload = blobFromFile(file);
    if (file.name) upload.name = file.name;
    data[opts.fieldName] = upload;

    try {
      const fetchResult = await getApolloClient().mutate({
        mutation: opts.mutation,
        variables: {data},
      });
      const {data: successData} = fetchResult;
      this.uppy.emit('upload-success', file, successData);
      return file;
    } catch (err) {
      this.uppy.emit('upload-error', file, err);
      throw err;
    } finally {
      this.uploaderEvents[file.id].remove();
      delete this.uploaderEvents[file.id];
    }
  }

  uploadRemote(file, current, total) {
    return Promise.reject(new Error('Remote file upload not supported yet'));
  }

  log(msg) {
    this.uppy.log(`${LOG_TAG} ${msg}`);
  }

  getFileOptions(file) {
    const overrides = this.uppy.getState().ahpGqlUpload;
    const opts = {
      ...this.opts,
      ...(overrides || {}),
      ...(file.ahpGqlUpload || {}),
      headers: {},
    };
    Object.assign(opts.headers, this.opts.headers);
    if (overrides) {
      Object.assign(opts.headers, overrides.headers);
    }
    if (file.ahpGqlUpload) {
      Object.assign(opts.headers, file.ahpGqlUpload.headers);
    }

    return opts;
  }

  fileMetaVariables(meta, opts) {
    // If metaFields opts is not specified, send all file meta by default
    const metaFields = Array.isArray(opts.metaFields)
      ? opts.metaFields
      : Object.keys(meta);
    return metaFields.reduce((vars, field) => {
      vars[field] = meta[field];
      return vars;
    }, {});
  }
}

export default AhpGqlUpload;
